import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import TournamentCards from "../shared/Card/tournamentCards";
import { Link } from "react-router-dom";
import MainPageCrousle from "../shared/Card/MainPageCrousle";
import { useDispatch, useSelector } from "react-redux";
import { blog } from "../redux/features/blog/blogSlice";
import axios from "axios";

function UserDashboard() {
  const dispatch = useDispatch();
  const [blogData, setBlogData] = useState([]);
  const [tournaments, setTournaments] = useState([]);
  const [allTournaments, setAllTournaments] = useState([]);

  const basUrl = `${process.env.REACT_APP_CMS_PROTOCOL}${process.env.REACT_APP_CMS_HOST}${process.env.REACT_APP_CMS_PORT}`;
  const localPort = `${process.env.REACT_APP_CMS_PROTOCOL}${process.env.REACT_APP_CMS_HOST}${process.env.REACT_APP_PORT}`;

  const { user, isLoading, error } = useSelector((state) => state.blog);

  useEffect(() => {
    dispatch(blog());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      setBlogData(user);
    }
  }, [user]);

  useEffect(() => {
    const fetchTournaments = async () => {
      try {
        const getResponse = await axios.get(`${basUrl}/api/tournaments`);
        setTournaments(getResponse.data);
      } catch (error) {
        console.error("Failed to fetch tournaments:", error);
      }
    };

    fetchTournaments();
  }, []);

  useEffect(() => {
    const processedTournaments = new Set();

    const fetchExistingTournaments = async () => {
      try {
        // Fetch all existing tournaments
        const existingTournamentsResponse = await axios.get(
          `${localPort}/get_all_tournaments`
        );
        const existingTournaments = existingTournamentsResponse.data;
        setAllTournaments(existingTournaments?.data?.slice(0, 4));

        const filteredTournaments = tournaments.filter((tournament) => {
          return !existingTournaments?.data.some(
            (existingTournament) =>
              existingTournament.upComingDate === tournament.upComingDate
          );
        });

        if (filteredTournaments.length > 0) {
          for (const tournament of filteredTournaments) {
            if (!processedTournaments.has(tournament.id)) {
              const postResponse = await axios.post(
                `${localPort}/create_tournament`,
                {
                  tournament_name: tournament.title,
                  num_of_slot: tournament.playerSlot,
                  cms_tournament_id: tournament.id,
                  upComingDate: tournament.upComingDate,
                }
              );
              processedTournaments.add(tournament.id);
            }
          }
        }
      } catch (error) {
        console.error(
          "Failed to fetch existing tournaments or create new ones:",
          error
        );
      }
    };

    if (tournaments.length > 0) {
      fetchExistingTournaments();
    }
  }, [tournaments]);

  return (
    <Box
      sx={{
        height: "100%",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: 6,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "grey",
          borderRadius: "10px",
        },
      }}
    >
      <Box sx={{ marginTop: "3rem", padding: "2rem" }}>
        <MainPageCrousle data={blogData} />
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "2rem",
          }}
        >
          <Box
            sx={{
              fontFamily: "Poppins, sans-serif !important",
              fontWeight: 700,
              fontSize: "2.8rem",
              lineHeight: "4.2rem",
              color: "white",
            }}
          >
            Upcoming Tournaments
          </Box>
          <Link to="/tournaments">
            <Box
              sx={{
                fontFamily: "Poppins, sans-serif !important",
                fontWeight: 600,
                fontSize: "1.4rem",
                color: "white",
                background: "#535C68",
                padding: "1rem",
                borderRadius: "1rem",
              }}
            >
              See more...
            </Box>
          </Link>
        </Box>

        <TournamentCards
          item={tournaments}
          allTournaments={allTournaments}
          showArrowButton={false}
        />
      </Box>
    </Box>
  );
}

export default UserDashboard;
