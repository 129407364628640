import React from "react";
import { Box, Button } from "@mui/material";
import sampleImg from "../../Assests/Image/Team_Icon.svg";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const Slot = ({
  slotIndex,
  ownerUsername,
  onRegister,
  isSlotDisabled,
  slotData,
}) => {

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "1rem 1rem",
        backgroundColor: "#666BB1",
        width: "auto",
        borderRadius: "3.5rem 0.8rem 0.8rem 3.5rem",
      }}
    >
      {ownerUsername || slotData ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            gap: "0.5rem",
          }}
        >
          <Box
            component="img"
            src={slotData.owner_profile_pic ? `data:image/png;base64,${slotData?.owner_profile_pic}` : sampleImg}
            alt="userImg"
            sx={{
              width: "6rem",
              height: "6rem",
              borderRadius: "50%",
              objectFit: "cover",
              display: "block",
              aspectRatio: "1 / 1",
            }}
          />
          <Box>
            <Box
              sx={{
                fontFamily: "Poppins, sans-serif !important",
                fontWeight: 300,
                fontSize: { xs: "1.2rem", sm: "1.4rem", md: "1.6rem" },
                lineHeight: { xs: "1.8rem", sm: "2.1rem", md: "2.4rem" },
                color: "white",
              }}
            >
              Slot Selected
            </Box>
            <Box
              sx={{
                fontFamily: "Poppins, sans-serif !important",
                fontWeight: 700,
                fontSize: { xs: "0.8rem", sm: "1rem", md: "1.6rem" },
                lineHeight: { xs: "1.2rem", sm: "1.5rem", md: "2.4rem" },
                color: "#C8E9EC",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: { xs: "10rem", sm: "12rem", md: "15rem" },
              }}
            >
              {ownerUsername}'s Teams
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            gap: "0.5rem",
          }}
          onClick={!isSlotDisabled ? onRegister : null}
        >
          <AddCircleIcon
            sx={{
              width: "6rem",
              height: "6rem",
              borderRadius: "50%",
              objectFit: "cover",
              display: "block",
              aspectRatio: "1 / 1",
              opacity: isSlotDisabled ? 0.5 : 1,
              cursor: isSlotDisabled ? "not-allowed" : "pointer",
            }}
          />
          <Box>
            <Box
              sx={{
                fontFamily: "Poppins, sans-serif !important",
                fontWeight: 300,
                fontSize: { xs: "1.2rem", sm: "1.4rem", md: "1.6rem" },
                lineHeight: { xs: "1.8rem", sm: "2.1rem", md: "2.4rem" },
                color: "white",
              }}
            >
              Slot Available
            </Box>
            <Button
              sx={{
                fontFamily: "Poppins, sans-serif !important",
                fontWeight: 700,
                fontSize: { xs: "0.8rem", sm: "1rem", md: "1.6rem" },
                lineHeight: { xs: "1.2rem", sm: "1.5rem", md: "2.4rem" },
                color: "#C8E9EC",
                textTransform: "none",
              }}
              disabled={isSlotDisabled}
              onClick={onRegister}
            >
              Click to register
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Slot;
