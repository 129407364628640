import React from "react";
import Slot from "./Slot";
import { Box } from "@mui/material";

const Group = ({ name, selectedSlots, onRegister, isGroupEnabled }) => {
  const groupSlots = selectedSlots.filter((slot) => slot.group_name === name);

  return (
    <Box sx={{ textAlign: "center" }}>
      <Box
        sx={{
          fontFamily: "Poppins, sans-serif !important",
          fontWeight: 600,
          fontSize: { xs: "1.6rem", sm: "2rem", md: "2.4rem" },
          lineHeight: { xs: "2.4rem", sm: "3rem", md: "3.6rem" },
          color: "white",
          background: "#666BB1",
          borderRadius: "0.6rem",
          marginBottom: "1rem",
        }}
      >
        {name}
      </Box>

      <Box sx={{ display: "flex", gap: "5rem" }}>
        {Array.from({ length: 2 }).map((_, slotIndex) => {
          const slotData = groupSlots[slotIndex];
          const ownerUsername = slotData?.owner_username || null;
          return (
            <Slot
              key={slotIndex}
              slotData={slotData}
              slotIndex={slotIndex}
              ownerUsername={ownerUsername}
              onRegister={() => onRegister(slotIndex)}
              isSlotDisabled={!isGroupEnabled || Boolean(ownerUsername)}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default Group;
