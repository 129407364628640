import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { blog } from "../redux/features/blog/blogSlice";
import FullBolg from "./FullBolg";

function Blogs() {
  const dispatch = useDispatch();
  const [blogData, setBlogData] = useState([]);
  const [showFullBlog, setShowFullBlog] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const { user } = useSelector((state) => state.blog);

  useEffect(() => {
    dispatch(blog());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      setBlogData(user);
    }
  }, [user]);

  const handleReadFullBlog = (data) => {
    setSelectedBlog(data);
    setShowFullBlog(true);
  };

  return (
    <Box
      sx={{
        height: "100%",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: 6,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "grey",
          borderRadius: "10px",
        },
      }}
    >
      {!showFullBlog ? (
        <Box>
          {blogData.map((data, idx) => (
            <Box
              sx={{
                borderBottom: "1px solid #3F3F3F",
                padding: "2rem 2rem 2rem 2rem",
                display: "flex",
                flexDirection: { xs: "column", sm: "column", md: "row" },
              }}
              key={idx}
            >
              <Box
                sx={{
                  width: { sm: "100%", md: "40%" },
                  height: { xs: "14.4rem", sm: "24.6rem", md: "21rem" },
                }}
              >
                <img
                  src={data.image}
                  alt="blog"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: "1rem",
                  }}
                />
              </Box>
              <Box
                sx={{
                  padding: "1rem 1rem",
                  width: { sm: "100%", md: "60%" },
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    fontFamily: "Squada One, sans-serif",
                    fontWeight: 400,
                    fontSize: "1.5rem",
                    color: "#7F00FF",
                  }}
                >
                  {data.days}
                </Box>
                <Box
                  sx={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 700,
                    fontSize: { xs: "1.6rem", sm: "2.4rem", md: "2.4rem" },
                    color: "#FAFAFA",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                    flexWrap: "wrap",
                    wordBreak: "break-word",
                  }}
                >
                  Heading: {data.title}
                </Box>

                <Box
                  sx={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 500,
                    fontSize: { xs: "0.8rem", sm: "1.3rem", md: "1rem" },
                    color: "#F6F6F6",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 6,
                    WebkitBoxOrient: "vertical",
                    whiteSpace: "normal",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: data.content
                      .replace(
                        /<table/g,
                        '<table style="border: 2px solid white; border-collapse: collapse; width: 100%;"'
                      )
                      .replace(
                        /<td/g,
                        '<td style="border: 2px solid white; padding: 5px; text-align: left;"'
                      )
                      .replace(
                        /<th/g,
                        '<th style="border: 2px solid white; padding: 5px; text-align: left;"'
                      ),
                  }}
                />
                <Box
                  sx={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 400,
                    fontSize: { xs: "1rem", sm: "1.2rem", md: "1.2rem" },
                    background: "#535C68",
                    borderRadius: "0.7rem",
                    padding: "0.56rem",
                    color: "white",
                    position: "absolute",
                    bottom: "1rem",
                    right: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => handleReadFullBlog(data)}
                >
                  Read Full Blog
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <FullBolg
          setShowFullBlog={setShowFullBlog}
          selectedBlog={selectedBlog}
        />
      )}
    </Box>
  );
}

export default Blogs;
