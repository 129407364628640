import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import GamesBg from "../Assests/HallOfFame/GamesBg.svg";
import CustomHallOfFame from "../shared/CustomCarousel/CustomHallOfFame";
import axios from "axios";
function HallOfFrame() {
  const [data, setData] = useState([]);

  const basUrl = `${process.env.REACT_APP_CMS_PROTOCOL}${process.env.REACT_APP_CMS_HOST}${process.env.REACT_APP_CMS_PORT}`;

  useEffect(() => {
    const fetchGameData = async () => {
      try {
        const response = await axios.get(`${basUrl}/api/lastest_fame`);
        const newData = response.data.data;
        setData(newData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchGameData();
  }, [basUrl]);

  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)), url(${GamesBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: { xs: "4rem", sm: "6rem", md: "8rem" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            color: "#F3F7EC",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: { xs: "2rem", sm: "3.2rem", md: "4.8rem" },
            lineHeight: { xs: "2.2rem", sm: "4.8rem", md: "7.2rem" },
            textAlign: "left",
          }}
        >
          Hall of Fame
        </Box>
      </Box>
      <Box>
        <CustomHallOfFame items={data} />
      </Box>
    </Box>
  );
}

export default HallOfFrame;
