import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import GamesBg from "../Assests/Image/GamesBg.svg";
import CustomCarousel from "../shared/CustomCarousel/CustomCarousel";
import axios from "axios";

function Games() {
  const [items, setItems] = useState([]);
  const basUrl = `${process.env.REACT_APP_CMS_PROTOCOL}${process.env.REACT_APP_CMS_HOST}${process.env.REACT_APP_CMS_PORT}`;

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await axios.get(`${basUrl}/api/games`);
        const gamesData = response.data;
        const formattedItems = gamesData.map((game) => ({
          gameTitle: game.gametitle,
          image: game.game_image,
          device: game.devices.map((dev) => ({ item: dev })),
        }));

        setItems(formattedItems);
      } catch (error) {
        console.error("Failed to fetch games:", error);
      }
    };

    fetchGames();
  }, [basUrl]);

  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)), url(${GamesBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: { xs: "4rem", sm: "6rem", md: "8rem" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            color: "#F3F7EC",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: { xs: "2rem", sm: "3.2rem", md: "4.8rem" },
            lineHeight: { xs: "2.2rem", sm: "4.8rem", md: "7.2rem" },
            textAlign: "left",
          }}
        >
          Games we host!
        </Box>
      </Box>
      <Box sx={{ paddingTop: { xs: "2rem", sm: "4rem", md: "6rem" } }}>
        <CustomCarousel items={items} />
      </Box>
    </Box>
  );
}

export default Games;
