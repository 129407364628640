import { Box, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

function History({ onDataFetch }) {
  const { user } = useSelector((state) => state.dashboard);
  const [itemss, setItems] = useState([]);
  const [tournamentData, setTournamentData] = useState([]);

  const localPort = `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}`;
  const basUrl = `${process.env.REACT_APP_CMS_PROTOCOL}${process.env.REACT_APP_CMS_HOST}${process.env.REACT_APP_CMS_PORT}`;

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        // Fetch upcoming matches
        const response = await axios.get(
          `${localPort}/upcoming-matches/${user?.username}`
        );

        // const matches = ;

        // Fetch tournament details and team details simultaneously
        const dataPromises = response?.data?.upcoming_matches.map(
          async (match) => {
            // Fetch tournament details
            const tournamentResponse = await axios.get(
              `${basUrl}/api/tournaments/${match.group.tournament.cms_tournament_id}`
            );

            // Fetch team details (if necessary)
            const teamResponse = await axios.get(
              `${localPort}/tournaments/${match.group.tournament_id}`
            );

            return {
              ...match,
              tournamentDetails: tournamentResponse.data,
              teamDetails: teamResponse.data,
            };
          }
        );
        const combinedResults = await Promise.all(dataPromises);

        // Set the combined results in the state
        setTournamentData(combinedResults);

        onDataFetch(tournamentData?.length);
        setItems(tournamentData?.length);
      } catch (error) {
        console.error("Error fetching history data:", error);
      }
    };

    fetchHistory();
  }, [user?.username, localPort, basUrl]);

  function formatTournamentDate(dateString) {
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleDateString("en-US", options).replace(",", "");
  }

  return (
    <Box
      sx={{
        height: "100%",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: 6,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "grey",
          borderRadius: "10px",
        },
      }}
    >
      {tournamentData.length > 0 ? (
        tournamentData.map((item, idx) => (
          <>
            <Box sx={{ padding: "2rem" }}>
              <Box
                key={idx}
                sx={{
                  borderBottom: "1px solid #3F3F3F",
                  padding: "1.2rem",
                  display: "flex",
                  borderRadius: "1.6rem",
                  background:
                    "linear-gradient(94.48deg, #3A3C3E 0%, #303133 50%, #232526 100%)",
                  boxShadow: "10px 4px 10px 0px #3A3C3E",
                }}
              >
                <Box>
                  <Box
                    component="img"
                    src={item?.tournamentDetails?.icon}
                    alt={item?.tournamentDetails?.title}
                    sx={{
                      width: { xs: "15.4rem", sm: "17.6rem", md: "28.2rem" },
                      height: { xs: "8.6rem", sm: "10rem", md: "16rem" },
                      borderRadius: "0.9rem",
                      objectFit: "cover",
                    }}
                  />
                  <Box
                    sx={{
                      fontFamily: "Poppins, sans-serif !important",
                      fontWeight: 500,
                      fontSize: { xs: "0.6rem", sm: "0.8rem", md: "1rem" },
                      lineHeight: { xs: "0.9rem", sm: "1.2rem", md: "1.5rem" },
                      color: "white",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {formatTournamentDate(
                      item?.tournamentDetails?.upComingDate
                    )}
                  </Box>
                  <Box
                    sx={{
                      fontFamily: "Poppins, sans-serif !important",
                      fontWeight: 600,
                      fontSize: { xs: "0.8rem", sm: "1rem", md: "1.4rem" },
                      lineHeight: { xs: "1.2rem", sm: "1.5rem", md: "2.1rem" },
                      color: "white",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item?.tournamentDetails?.title}
                  </Box>
                </Box>
                <Box sx={{ width: "100%" }}>
                  {item?.teamDetails?.groups?.map((group, groupIndex) => (
                    <Box key={groupIndex} sx={{ alignContent: "center" }}>
                      <Box
                        sx={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 700,
                          fontSize: { xs: "1.2rem", sm: "2rem", md: "3.6rem" },
                          lineHeight: {
                            xs: "0.9rem",
                            sm: "4.5rem",
                            md: "4.5rem",
                          },
                          color: "#686DE0",
                          textAlign: "center",
                        }}
                      >
                        Teams:{" "}
                        <span style={{ color: "white" }}>
                          {group?.owner_username}
                        </span>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          justifyContent: "space-evenly",
                          padding: "2rem",
                        }}
                      >
                        {group.teams.length > 0 &&
                          group.teams.map((team, teamIndex) => (
                            <Box
                              key={team.id}
                              sx={{
                                display: "flex",
                              }}
                            >
                              <Box sx={{ display: "flex", gap: "0.5rem" }}>
                                <Box
                                  component="img"
                                  src={`data:image/png;base64,${team?.profile_pic}`}
                                  alt="gameImg"
                                  sx={{
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                    height: {
                                      xs: "1.6rem",
                                      sm: "3rem",
                                      md: "4.8rem",
                                    },
                                    width: {
                                      xs: "1.6rem",
                                      sm: "3rem",
                                      md: "4.8rem",
                                    },
                                  }}
                                />
                                <Box
                                  sx={{
                                    fontFamily: "Poppins sans, sans-serif",
                                    fontWeight: 500,
                                    fontSize: {
                                      xs: "0.8rem",
                                      sm: "1.1rem",
                                      md: "1.6rem",
                                    },
                                    lineHeight: {
                                      xs: "1rem",
                                      sm: "1.6rem",
                                      md: "2.4rem",
                                    },
                                    color: "white",
                                  }}
                                >
                                  {team.name}
                                </Box>
                              </Box>
                            </Box>
                          ))}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
            <Divider
              sx={{ width: "100%", borderBottom: "1px solid #3F3F3F" }}
            />
          </>
        ))
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Box
            sx={{
              fontFamily: "Poppins, sans-serif !important",
              fontWeight: 400,
              fontSize: { xs: "1.2rem", sm: "2rem", md: "2.4rem" },
              lineHeight: { xs: "1.8rem", sm: "3rem", md: "3.6rem" },
              color: "#505658",
              textAlign: "center",
            }}
          >
            Don’t be shy, <br />
            go ahead and register for more matches....
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default History;
