import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const basUrl = `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}`;

export const postCommunity = createAsyncThunk(
  "postCommunity",
  async (formValues, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${basUrl}/api/post/community`,
        formValues
      );
      return response.data; // Make sure to return response data
    } catch (error) {
      // More descriptive error handling
      return rejectWithValue(
        error.response?.data || "An error occurred while posting community."
      );
    }
  }
);

const postCommunitySlice = createSlice({
  name: "postCommunity",
  initialState: {
    postCommunityisLoading: false,
    postCommunityuser: null,
    postCommunitystatus: "idle",
    postCommunityerror: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(postCommunity.pending, (state) => {
        state.postCommunityisLoading = true;
        state.postCommunitystatus = "loading";
      })
      .addCase(postCommunity.fulfilled, (state, action) => {
        state.postCommunityisLoading = false;
        state.postCommunitystatus = "succeeded";
        state.postCommunityuser = action.payload;
      })
      .addCase(postCommunity.rejected, (state, action) => {
        state.postCommunityisLoading = false;
        state.postCommunitystatus = "failed";
        state.postCommunityerror = action.payload;
      });
  },
});

export default postCommunitySlice.reducer;
