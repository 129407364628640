import { Box, Select, MenuItem } from "@mui/material";
import React from "react";

function CustomDropDownwithLabel({
  label,
  required,
  onChange,
  name,
  value,
  options,
  selectedState,
  setSelectedState,
}) {
  const handleChange = (event) => {
    setSelectedState(event.target.value);
    onChange(event);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        width: "100%",
        gap: { xs: 0, sm: 2 },
        mb: { xs: 2, sm: 3 },
      }}
    >
      <Box
        sx={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: 300,
          fontSize: { xs: "1.4rem", sm: "2rem", md: "2.4rem" },
          lineHeight: { xs: "2.1rem", sm: "3rem", md: "3.6rem" },
          color: "#181818",
          marginBottom: { xs: "0.5rem", sm: 0 },
          minWidth: { sm: "40%" },
          textAlign: "left",
        }}
      >
        {label}
      </Box>
      <Select
        displayEmpty
        renderValue={(selected) => (selected ? selected : "Select a State")}
        fullWidth
        name={name}
        value={selectedState}
        required={required}
        onChange={handleChange}
        sx={{
          borderRadius: "4.6rem",
          border: "0.2rem solid #36769D",
          fontFamily: "Poppins, sans-serif",
          fontWeight: 300,
          fontSize: { xs: "1.4rem", sm: "2rem", md: "2.4rem" },
          lineHeight: { xs: "2.1rem", sm: "3rem", md: "3.6rem" },
          color: "#423F63",
          height: "4.5rem",
          padding: "0 1rem",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none",
            },
            "&:hover fieldset": {
              border: "none",
            },
            "&.Mui-focused fieldset": {
              border: "none",
            },
          },
          "& .MuiInputBase-input::placeholder": {
            fontFamily: "Poppins, sans-serif",
            fontWeight: 300,
            fontSize: { xs: "1.4rem", sm: "2rem", md: "2.4rem" },
            lineHeight: { xs: "2.1rem", sm: "3rem", md: "3.6rem" },
            color: "#423F63",
            opacity: 1,
          },
          "& .MuiInputBase-input": {
            fontFamily: "Poppins, sans-serif",
            fontWeight: 300,
            fontSize: { xs: "1.4rem", sm: "2rem", md: "2.4rem" },
            lineHeight: { xs: "2.1rem", sm: "3rem", md: "3.6rem" },
            color: "#423F63",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option} sx={{ fontSize: "1.4em" }}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}

export default CustomDropDownwithLabel;
