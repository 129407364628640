import React, { useCallback, useEffect, useState } from "react";
import Group from "./Group";
import {
  Container,
  Box,
  Modal,
  Divider,
  Typography,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import { useSelector } from "react-redux";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import trophy from "../../Assests/Dashboard/blog/trophy.svg";
import squad from "../../Assests/Dashboard/blog/squad.svg";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import TeamImg from "../../Assests/Image/Team_Icon.svg";

const TournamentLayout = ({
  selectedTournament,
  userData,
  userImage,
  selectedSlots,
}) => {
  const getGroupName = (index) => `Group ${String.fromCharCode(65 + index)}`;

  const formatWinPrice = (price) => {
    return price >= 1000 ? `${(price / 1000).toFixed(0)}k` : price;
  };

  const [open, setOpen] = useState(false);
  const [team, setTeam] = useState("");
  const [teamNames, setTeamName] = useState([]);
  const [expandedTeam, setExpandedTeam] = useState(null);
  const [teamUsers, setTeamUsers] = useState({});
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedGroupName, setSelectedGroupName] = useState(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const { user, isLoading, error } = useSelector((state) => state.dashboard);

  const localPort = `${process.env.REACT_APP_CMS_PROTOCOL}${process.env.REACT_APP_CMS_HOST}${process.env.REACT_APP_PORT}`;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleRegister = (groupIndex, slotIndex, groupName) => {
    setSelectedGroup(groupIndex);
    setSelectedSlot(slotIndex);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTeam("");
    setSelectedPlayers([]);
  };

  const handleAccordionToggle = useCallback(
    async (teamId) => {
      if (expandedTeam === teamId) {
        setExpandedTeam(null);
      } else {
        if (!teamUsers[teamId]) {
          try {
            const response = await axios.get(
              `${localPort}/teams/${teamId}/players`
            );
            if (response.data.status === "ok") {
              const teamData = response.data.team;
              const teamPlayers = teamData.team_players;
              setTeamUsers((prevUsers) => ({
                ...prevUsers,
                [teamId]: teamPlayers,
              }));
            } else {
              console.error("Failed to fetch team data");
            }
          } catch (error) {
            console.error("Error fetching team data:", error);
          } finally {
          }
        }
        setExpandedTeam(teamId);
      }
    },
    [expandedTeam, teamUsers, localPort]
  );

  useEffect(() => {
    const fetchTeamNames = async () => {
      try {
        const response = await axios.get(`${localPort}/teams`, {
          params: { owner_username: user?.username },
        });
        setTeamName(response.data.teams); // Assuming API returns a 'teams' array
      } catch (error) {
        console.error("Error fetching team names:", error);
      }
    };

    fetchTeamNames();
  }, []);

  function formatTournamentDate(dateString) {
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleDateString("en-US", options).replace(",", "");
  }

  const handleAddPlayer = (player, teamId) => {
    setSelectedPlayers((prev) => [...prev, player]);
    setTeamUsers((prev) => ({
      ...prev,
      [teamId]: (prev[teamId] || []).filter(
        (p) => p.player_username !== player.player_username
      ),
    }));
  };

  const handleRemovePlayer = (player) => {
    setSelectedPlayers((prev) =>
      prev.filter((p) => p.player_username !== player.player_username)
    );
    setTeamUsers((prev) => {
      const teamId = player.teamId;
      return {
        ...prev,
        [teamId]: [...(prev[teamId] || []), player],
      };
    });
  };

  const handleRegisterTeam = async (tournamentID) => {
    try {
      if (selectedTournament.playerSlot - 1 !== selectedPlayers.length) {
        setSnackbarSeverity("warning");
        setSnackbarMessage("Player slots have not been filled.");
        setSnackbarOpen(true);
        return;
      }

      // Step 1: Register the team group
      const registerResponse = await axios.post(
        `${localPort}/tournaments/${tournamentID}/groups`,
        {
          group_name: selectedGroupName,
          owner_username: user?.username,
        }
      );
      const groupId = registerResponse.data.id;

      if (!groupId) {
        throw new Error(
          "Group ID is not available in the registration response"
        );
      }

      // Step 2: Add players to the team
      const playerUsernames = [
        ...selectedPlayers.map((item) => item.player_username),
        user?.username,
      ];

      for (const player of playerUsernames) {
        try {
          await axios.post(`${localPort}/groups/${groupId}/teams`, {
            team_player_name: player,
            tournament_id: tournamentID,
          });
        } catch (error) {
          const errorMessage = error.response?.data?.error || error.message;
          setSnackbarSeverity("error");
          setSnackbarMessage(errorMessage);
          setSnackbarOpen(true);
          return; // Exit loop on error to avoid further processing
        }
      }

      // Step 3: Show success message after all players are added
      setOpen(false);
      setSnackbarSeverity("success");
      setSnackbarMessage("Team successfully registered!");
      setSnackbarOpen(true);
      window.location.reload();
    } catch (error) {
      const errorMessage = error.response?.data?.error || error.message;
      const groupName = error.response?.data?.group_name;

      const detailedMessage = groupName
        ? `${errorMessage} (${groupName})`
        : errorMessage;

      setSnackbarSeverity("error");
      setSnackbarMessage(detailedMessage);
      setSnackbarOpen(true);
    }
  };

  const checkSlotsAndTriggerSnackbar = () => {
    if (selectedPlayers.length >= selectedTournament.playerSlot) {
      setOpenSnackbar(true);
    }
  };

  // Call this function inside useEffect or a specific event handler to avoid infinite re-renders
  useEffect(() => {
    checkSlotsAndTriggerSnackbar();
  }, [selectedPlayers, selectedTournament.playerSlot]);

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (selectedPlayers.length >= selectedTournament.playerSlot) {
      handleOpenSnackbar();
    }
  }, [selectedPlayers, selectedTournament.playerSlot]);

  return (
    <Container
      sx={{
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        color: "white",
      }}
    >
      <Box
        sx={{
          display: "inline-block",
          position: "relative",
          padding: "0.5rem 2rem",
          background: "linear-gradient(180deg, #5961F9 0%, #3A4CEB 100%)",
          textAlign: "center",
          borderRadius: "4px",
          color: "white",
          textTransform: "uppercase",
          fontFamily: "Poppins, sans-serif !important",
          fontWeight: 700,
          fontSize: { xs: "2.4rem", sm: "3.2rem", md: "4rem" },
          lineHeight: { xs: "3.6rem", sm: "4.8rem", md: "6rem" },
        }}
      >
        {selectedTournament.title}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: {
            xs: "center",
            sm: "center",
            md: "space-around",
          },
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
          },
          alignItems: {
            xs: "center",
            sm: "center",
            md: "flex-start",
          },
          gap: {
            xs: "1rem",
            sm: "1.5rem",
            md: "2rem",
          },
          width: "100%",
          position: "relative",
          flexWrap: "wrap",
          padding: { xs: "1rem", md: "2rem" },
        }}
      >
        {selectedTournament && selectedSlots && selectedSlots.groups && (
          <>
            {Array.from({
              length: Math.ceil(selectedTournament.Numberofmatches / 2),
            }).map((_, index) => {
              const group = selectedSlots.groups[index];
              const generatedGroupName = getGroupName(index);
              const selectedIndex = selectedSlots.groups.length / 2;
              const arr = [];
              for (let i = 0; i <= selectedIndex; i++) {
                arr.push(i);
              }

              const isGroupEnabled = arr.includes(index);

              return (
                <Group
                  key={group ? group.id : index}
                  name={generatedGroupName}
                  selectedSlots={selectedSlots.groups}
                  onRegister={(slotIndex) => {
                    setSelectedGroupName(generatedGroupName);
                    handleRegister(
                      index,
                      slotIndex,
                      group ? group.group_name : ""
                    );
                  }}
                  isGroupEnabled={isGroupEnabled}
                />
              );
            })}
          </>
        )}
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#535C68",
            borderRadius: "1.6rem",
            padding: "1rem",
            width: { xs: "80%", sm: "50%", md: "40%" },
            outline: "none",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                marginBottom: "1rem",
              }}
            >
              <Box
                component="img"
                src={selectedTournament?.icon}
                alt="gameImg"
                sx={{
                  objectFit: "cover",
                  borderRadius: "1.4rem",
                  height: { xs: "6.6rem", sm: "9.9rem", md: "9.9rem" },
                  width: { xs: "11.9rem", sm: "17.5rem", md: "17.5rem" },
                }}
              />
              <Box>
                <Box
                  sx={{
                    fontFamily: "Poppins, sans-serif !important",
                    fontWeight: 500,
                    fontSize: { xs: "1.4rem", sm: "1.4rem", md: "1rem" },
                    lineHeight: { xs: "2.1rem", sm: "2.1rem", md: "1.5rem" },
                    color: "white",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {formatTournamentDate(selectedTournament.upComingDate)}
                </Box>
                <Box
                  sx={{
                    fontFamily: "Poppins, sans-serif !important",
                    fontWeight: 600,
                    fontSize: { xs: "1.1rem", sm: "1.5rem", md: "1.5rem" },
                    lineHeight: { xs: "1.6rem", sm: "2.2rem", md: "2.2rem" },
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexWrap: "wrap",
                    color: "white",
                  }}
                >
                  {selectedTournament.title}
                </Box>
                <Box
                  sx={{
                    fontFamily: "Poppins, sans-serif !important",
                    fontWeight: 500,
                    fontSize: { xs: "0.6rem", sm: "1rem", md: "1rem" },
                    lineHeight: { xs: "0.9rem", sm: "1.5rem", md: "1.5rem" },
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 4,
                    color: "#CBCBCC",
                  }}
                >
                  {selectedTournament.description}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                background: "#2A2E44",
                borderRadius: "0.7rem",
                height: "3rem",
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                padding: "0rem 0.5rem",
                cursor: "pointer",
              }}
              onClick={() =>
                handleRegisterTeam(selectedTournament?.allTournamentData?.id)
              }
            >
              <Box
                sx={{
                  fontFamily: "Inter, sans-serif !important",
                  fontWeight: 465,
                  fontSize: { xs: "1rem", sm: "1.6rem", md: "1.6rem" },
                  lineHeight: { xs: "1.2rem", sm: "2rem", md: "2rem" },
                  color: "white",
                }}
              >
                Register
              </Box>
              <ArrowCircleRightIcon
                sx={{ height: "2.5rem", width: "2.5rem", color: "#686DE0" }}
              />
            </Box>
          </Box>
          <Divider sx={{ backgroundColor: "#8B8B8B", width: "100%" }} />
          <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
            <Box
              sx={{
                display: "inlineflex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Box
                sx={{
                  fontFamily: "Poppins, sans-serif !important",
                  fontWeight: 500,
                  fontSize: { xs: "1.4rem", sm: "1.4rem", md: "1.6rem" },
                  lineHeight: {
                    xs: "2.1rem",
                    sm: "2.1rem",
                    md: "2.4rem",
                  },
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  color: "#CBCBCC",
                }}
              >
                Win Price
              </Box>
              <Box>
                <img
                  src={trophy}
                  alt="trophy"
                  style={{
                    height: {
                      xs: "1.5rem",
                      sm: "1.5rem",
                      md: "2rem",
                    },
                    width: {
                      xs: "1.5rem",
                      sm: "1.5rem",
                      md: "2rem",
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  fontFamily: "Poppins, sans-serif !important",
                  fontWeight: 700,
                  fontSize: { xs: "1.2rem", sm: "2rem", md: "1.4rem" },
                  lineHeight: {
                    xs: "1.8rem",
                    sm: "2.5rem",
                    md: "2.1rem",
                  },
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",

                  color: "white",
                }}
              >
                {formatWinPrice(selectedTournament?.winPrice)}
              </Box>
            </Box>
            <Box
              sx={{
                display: "inlineflex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Box
                sx={{
                  fontFamily: "Poppins, sans-serif !important",
                  fontWeight: 500,
                  fontSize: { xs: "1.4rem", sm: "1.4rem", md: "1.6rem" },
                  lineHeight: {
                    xs: "2.1rem",
                    sm: "2.1rem",
                    md: "2.4rem",
                  },
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,

                  color: "#CBCBCC",
                }}
              >
                Player Slot
              </Box>
              <Box>
                <img
                  src={squad}
                  alt="trophy"
                  style={{
                    height: {
                      xs: "1.5rem",
                      sm: "1.5rem",
                      md: "2rem",
                    },
                    width: {
                      xs: "1.5rem",
                      sm: "1.5rem",
                      md: "2rem",
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  fontFamily: "Poppins, sans-serif !important",
                  fontWeight: 700,
                  fontSize: { xs: "1.2rem", sm: "2rem", md: "1.4rem" },
                  lineHeight: {
                    xs: "1.8rem",
                    sm: "2.5rem",
                    md: "2.1rem",
                  },
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  color: "white",
                }}
              >
                {selectedTournament?.playerSlot}v
                {selectedTournament?.playerSlot}
              </Box>
            </Box>
          </Box>
          <Divider sx={{ backgroundColor: "#8B8B8B", width: "100%" }} />
          <Box sx={{ display: "flex", padding: "0.5rem", gap: "0.5rem" }}>
            <Box sx={{ width: "50%", gap: "0.5rem" }}>
              <Box sx={{ display: "flex" }}>
                <Box
                  component="img"
                  src={userImage}
                  alt="userImg"
                  sx={{
                    width: { xs: "6.8rem", sm: "10rem", md: "10rem" },
                    height: { xs: "6.8rem", sm: "10rem", md: "10rem" },
                    borderRadius: "50%",
                    objectFit: "cover",
                    display: "block",
                    aspectRatio: "1 / 1",
                  }}
                />
                <Box sx={{ flex: 1 }}>
                  <Box
                    sx={{
                      fontFamily: "Inter, sans-serif",
                      fontWeight: 600,
                      fontSize: { xs: "1.5rem", sm: "2.2rem", md: "2.2rem" },
                      lineHeight: {
                        xs: "1.7rem",
                        sm: "2.6rem",
                        md: "2.6rem",
                      },
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      color: "white",
                    }}
                  >
                    {userData?.username}
                  </Box>
                  <Box
                    sx={{
                      fontFamily: "Inter, sans-serif",
                      fontWeight: 600,
                      fontSize: { xs: "0.6rem", sm: "1rem", md: "1rem" },
                      lineHeight: {
                        xs: "0.7rem",
                        sm: "1.2rem",
                        md: "1.2rem",
                      },
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      color: "#CBCBCC",
                    }}
                  >
                    You will be the IGl of this team, select your teammates and
                    build your roster for this match...
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  height: "14rem",
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    width: 6,
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "grey",
                    borderRadius: "10px",
                  },
                }}
              >
                {selectedPlayers &&
                  Array.from({
                    length: selectedTournament.playerSlot - 1,
                  }).map((_, index) => (
                    <Box
                      key={index}
                      sx={{
                        background: "#8F9EB1",
                        borderRadius: "1.8rem",
                        padding: "0.5rem",
                        flex: "1 1 auto",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        minHeight: "2.5rem",
                        maxHeight: "2.5rem",
                        height: "2.5rem",
                      }}
                    >
                      <Box
                        sx={{
                          color: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                          textAlign: "center",
                        }}
                      >
                        {selectedPlayers[index]?.player_username || "..."}
                      </Box>

                      {selectedPlayers[index] && (
                        <Box
                          sx={{
                            background: "#686DE0",
                            borderRadius: "1.2rem",
                            alignContent: "center",
                            color: "white",
                            display: "flex",
                            gap: "0.5rem",
                            padding: "0.4rem 0.7rem",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleRemovePlayer(selectedPlayers[index], team.id)
                          }
                        >
                          <Box>Remove</Box>
                          <PersonRemoveIcon
                            sx={{ height: "1.4rem", width: "1.4rem" }}
                          />
                        </Box>
                      )}
                    </Box>
                  ))}
              </Box>
            </Box>
            <Box sx={{ width: "50%" }}>
              <Box
                sx={{
                  width: "100%",
                  border: "1px solid #949494",
                  borderRadius: "1.6rem",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 600,
                    fontSize: { xs: "1rem", sm: "1.5rem", md: "1.5rem" },
                    lineHeight: { xs: "1.5rem", sm: "2.2rem", md: "2.2rem" },
                    color: "white",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    background: "#8F9EB1",
                    borderRadius: "1.6rem 1.6rem 0rem 0rem",
                    textAlign: "center",
                    padding: "0.5rem",
                  }}
                >
                  Select members from a team
                </Box>
                {teamNames.length > 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                      maxHeight: "20rem",
                      overflowY: "auto",
                      padding: "0.5rem",
                      "&::-webkit-scrollbar": {
                        width: 6,
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "grey",
                        borderRadius: "10px",
                      },
                    }}
                  >
                    {teamNames.map((team) => (
                      <Box
                        key={team.id}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          borderBottom: "1px solid #ccc",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "0.5rem",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: "0.5rem",
                              alignItems: "center",
                              maxWidth: "80%",
                            }}
                          >
                            <Box
                              component="img"
                              src={TeamImg}
                              alt="TeamImg"
                              sx={{
                                width: {
                                  xs: "2rem",
                                  sm: "2.8rem",
                                  md: "2.8rem",
                                },
                                height: {
                                  xs: "2rem",
                                  sm: "2.8rem",
                                  md: "2.8rem",
                                },
                                borderRadius: "50%",
                                objectFit: "cover",
                                display: "block",
                                aspectRatio: "1 / 1",
                              }}
                            />
                            <Box
                              sx={{
                                fontFamily: "Inter, sans-serif",
                                fontWeight: 600,
                                fontSize: {
                                  xs: "1rem",
                                  sm: "1.4rem",
                                  md: "1.4rem",
                                },
                                lineHeight: {
                                  xs: "1.5rem",
                                  sm: "2rem",
                                  md: "2rem",
                                },
                                color: "white",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "100%",
                                flexGrow: 1,
                              }}
                            >
                              {team.name}
                            </Box>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              gap: "2rem",
                              alignItems: "center",
                            }}
                            onClick={() => handleAccordionToggle(team.id)}
                          >
                            <Box>{teamUsers.length}</Box>
                            <Box
                              sx={{
                                background: "#686DE0",
                                borderRadius: "50%",
                                cursor: "pointer",
                              }}
                            >
                              {expandedTeam === team.id ? (
                                <ExpandMoreIcon sx={{ color: "white" }} />
                              ) : (
                                <KeyboardArrowRightIcon
                                  sx={{ color: "white" }}
                                />
                              )}
                            </Box>
                          </Box>
                        </Box>
                        {expandedTeam === team.id && (
                          <Box
                            sx={{
                              paddingLeft: "2rem",
                              paddingBottom: "0.5rem",
                            }}
                          >
                            {teamUsers[team.id] ? (
                              teamUsers[team.id].length > 0 ? (
                                teamUsers[team.id].map((player) => (
                                  <Box
                                    key={player.player_username}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      marginBottom: "0.5rem",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "0.5rem",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Box
                                        component="img"
                                        src={TeamImg}
                                        alt="TeamImg"
                                        sx={{
                                          width: {
                                            xs: "2rem",
                                            sm: "2.8rem",
                                            md: "2.8rem",
                                          },
                                          height: {
                                            xs: "2rem",
                                            sm: "2.8rem",
                                            md: "2.8rem",
                                          },
                                          borderRadius: "50%",
                                          objectFit: "cover",
                                          display: "block",
                                          aspectRatio: "1 / 1",
                                        }}
                                      />
                                      <Typography
                                        sx={{
                                          color: "white",
                                          fontSize: "0.9rem",
                                        }}
                                      >
                                        {player.player_username}
                                      </Typography>
                                    </Box>
                                    <Button
                                      variant="contained"
                                      color={
                                        selectedPlayers.some(
                                          (p) =>
                                            p.player_username ===
                                            player.player_username
                                        )
                                          ? "secondary"
                                          : "primary"
                                      }
                                      size="small"
                                      onClick={() => handleAddPlayer(player)}
                                      disabled={
                                        selectedPlayers.some(
                                          (p) =>
                                            p.player_username ===
                                            player.player_username
                                        ) ||
                                        selectedPlayers.length >=
                                          selectedTournament.playerSlot - 1
                                      }
                                    >
                                      {selectedPlayers.some(
                                        (p) =>
                                          p.player_username ===
                                          player.player_username
                                      )
                                        ? "Added"
                                        : "Add"}
                                    </Button>
                                  </Box>
                                ))
                              ) : (
                                <Typography sx={{ color: "white" }}>
                                  No players in this team.
                                </Typography>
                              )
                            ) : (
                              <Typography sx={{ color: "white" }}>
                                Loading...
                              </Typography>
                            )}
                          </Box>
                        )}
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Box>There is no team</Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%", fontSize: "1.4rem" }}
        >
          Can't be added, slots are full
        </Alert>
      </Snackbar>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%", fontSize: "1.4rem" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default TournamentLayout;
