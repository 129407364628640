import React from "react";
import { TextField, InputAdornment, Box } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

export default function CustomInput({
  placeholder,
  required,
  onChange,
  name,
  value,
  bordercolor,
  color,
  disabled,
  height,
}) {
  return (
    <div>
      <TextField
        disabled={disabled}
        fullWidth
        name={name}
        value={value}
        required={required}
        onChange={onChange}
        placeholder={placeholder}
        InputProps={{
          style: {
            margin: "0.5rem auto",
            borderRadius: "2rem",
            border: bordercolor ? bordercolor : "0.2rem solid #36769D",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 300,
            fontSize: { xs: "1.4rem", sm: "2rem", md: "2.4rem" },
            lineHeight: { xs: "2.1rem", sm: "3rem", md: "3.6rem" },
            color: color ? color : "#423F63",
            height: height ? height : "4.5rem",
            padding: "0 1rem",
          },
          endAdornment: (
            <InputAdornment position="end">
              <PersonIcon
                sx={{
                  height: "3.5rem",
                  width: "3.5rem",
                  color: color ? color : "#423F63",
                }}
              />
            </InputAdornment>
          ),
          classes: {
            notchedOutline: "custom-notchedOutline",
          },
        }}
        InputLabelProps={{
          style: {
            fontFamily: "Poppins, sans-serif",
            color: color ? color : "#423F63",
            fontWeight: 300,
            fontSize: { xs: "1.4rem", sm: "2rem", md: "2.4rem" },
            lineHeight: { xs: "2.1rem", sm: "3rem", md: "3.6rem" },
          },
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none",
            },
            "&:hover fieldset": {
              border: "none",
            },
            "&.Mui-focused fieldset": {
              border: "none",
            },
          },
          "& .MuiInputBase-input::placeholder": {
            fontFamily: "Poppins, sans-serif",
            fontWeight: 300,
            fontSize: { xs: "1.4rem", sm: "2rem", md: "2.4rem" },
            lineHeight: { xs: "2.1rem", sm: "3rem", md: "3.6rem" },
            color: color ? color : "#423F63",
            opacity: 1,
          },
          "& .MuiInputBase-input": {
            fontFamily: "Poppins, sans-serif",
            fontWeight: 300,
            fontSize: { xs: "1.4rem", sm: "2rem", md: "2.4rem" },
            lineHeight: { xs: "2.1rem", sm: "3rem", md: "3.6rem" },
            color: color ? color : "#423F63",
          },
        }}
      />
    </div>
  );
}

export function CustomInputWithoutIcon({
  placeholder,
  required,
  onChange,
  name,
  value,
  bordercolor,
  color,
  height,
}) {
  return (
    <div>
      <TextField
        fullWidth
        name={name}
        value={value}
        required={required}
        onChange={onChange}
        placeholder={placeholder}
        InputProps={{
          style: {
            margin: "0.5rem auto",
            borderRadius: "2rem",
            border: bordercolor ? bordercolor : "0.2rem solid #36769D",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 300,
            fontSize: { xs: "1.4rem", sm: "2rem", md: "2.4rem" },
            lineHeight: { xs: "2.1rem", sm: "3rem", md: "3.6rem" },
            color: color ? color : "#423F63",
            height: height ? height : "4.5rem",
            padding: "0 1rem",
          },
          classes: {
            notchedOutline: "custom-notchedOutline",
          },
        }}
        InputLabelProps={{
          style: {
            fontFamily: "Poppins, sans-serif",
            color: color ? color : "#423F63",
            fontWeight: 300,
            fontSize: { xs: "1.4rem", sm: "2rem", md: "2.4rem" },
            lineHeight: { xs: "2.1rem", sm: "3rem", md: "3.6rem" },
          },
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none",
            },
            "&:hover fieldset": {
              border: "none",
            },
            "&.Mui-focused fieldset": {
              border: "none",
            },
          },
          "& .MuiInputBase-input::placeholder": {
            fontFamily: "Poppins, sans-serif",
            fontWeight: 300,
            fontSize: { xs: "1.4rem", sm: "2rem", md: "2.4rem" },
            lineHeight: { xs: "2.1rem", sm: "3rem", md: "3.6rem" },
            color: color ? color : "#423F63",
            opacity: 1,
          },
          "& .MuiInputBase-input": {
            fontFamily: "Poppins, sans-serif",
            fontWeight: 300,
            fontSize: { xs: "1.4rem", sm: "2rem", md: "2.4rem" },
            lineHeight: { xs: "2.1rem", sm: "3rem", md: "3.6rem" },
            color: color ? color : "#423F63",
          },
        }}
      />
    </div>
  );
}

export function CustomInputWithFormLabel({
  label,
  placeholder,
  required,
  onChange,
  name,
  value,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        width: "100%",
        gap: { xs: 0, sm: 2 },
        mb: { xs: 1, sm: 3 },
      }}
    >
      <Box
        sx={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: 300,
          fontSize: { xs: "1.4rem", sm: "2rem", md: "2.4rem" },
          lineHeight: { xs: "2.1rem", sm: "3rem", md: "3.6rem" },
          color: "#181818",
          marginBottom: { xs: "0.5rem", sm: 0 },
          minWidth: { sm: "40%" },
          textAlign: "left",
        }}
      >
        {label}
      </Box>
      <TextField
        fullWidth
        name={name}
        value={value}
        required={required}
        onChange={onChange}
        placeholder={placeholder}
        InputProps={{
          style: {
            borderRadius: "4.6rem",
            border: "0.2rem solid #36769D",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 300,
            fontSize: { xs: "1.4rem", sm: "2rem", md: "2.4rem" },
            lineHeight: { xs: "2.1rem", sm: "3rem", md: "3.6rem" },
            color: "#423F63",
            height: "4.5rem",
            padding: "0 1rem",
          },
          classes: {
            notchedOutline: "custom-notchedOutline",
          },
        }}
        InputLabelProps={{
          style: {
            fontFamily: "Poppins, sans-serif",
            color: "#423F63",
            fontWeight: 300,
            fontSize: { xs: "1.4rem", sm: "2rem", md: "2.4rem" },
            lineHeight: { xs: "2.1rem", sm: "3rem", md: "3.6rem" },
            padding: "0 1rem",
          },
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none",
            },
            "&:hover fieldset": {
              border: "none",
            },
            "&.Mui-focused fieldset": {
              border: "none",
            },
          },
          "& .MuiInputBase-input::placeholder": {
            fontFamily: "Poppins, sans-serif",
            fontWeight: 300,
            fontSize: { xs: "1.4rem", sm: "2rem", md: "2.4rem" },
            lineHeight: { xs: "2.1rem", sm: "3rem", md: "3.6rem" },
            color: "#423F63",
            opacity: 1,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
          "& .MuiInputBase-input": {
            fontFamily: "Poppins, sans-serif",
            fontWeight: 300,
            fontSize: { xs: "1.4rem", sm: "2rem", md: "2.4rem" },
            lineHeight: { xs: "2.1rem", sm: "3rem", md: "3.6rem" },
            color: "#423F63",
          },
        }}
      />
    </Box>
  );
}
