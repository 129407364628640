import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Email from "../Assests/Email.svg";
import Discord from "../Assests/Discord.svg";
import Reddit from "../Assests/Reddit.svg";
import X from "../Assests/X.svg";
import Facebook from "../Assests/Facebook.svg";
import Instagram from "../Assests/Instagram.svg";
import Youtube from "../Assests/Youtube.svg";
import Quora from "../Assests/Quora.svg";
import { Helmet } from "react-helmet";

const EmailContact = ({ title, description, email, icon, link }) => {
  return (
  
    <Box>
      {title && (
        <Box
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 500,
            fontSize: { xs: "1.8rem", sm: "2rem", md: "3.2rem" },
            lineHeight: { xs: "2.5rem", sm: "2.5rem", md: "3.6rem" },
          }}
        >
          {title}
        </Box>
		
      )}
      <Box sx={{ display: "flex", alignItems: "center", gap: "0.4vw" }}>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <Box
            component="img"
            src={icon}
            alt="email"
            sx={{
              width: { xs: "4rem", sm: "5rem", md: "6rem" },
              height: { xs: "4rem", sm: "5rem", md: "6rem" },
            }}
          />
        </a>
        <Box>
          <Box
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 300,
              fontSize: { xs: "1.6rem", sm: "2rem", md: "2.4rem" },
              lineHeight: { xs: "2rem", sm: "2.5rem", md: "3.6rem" },
            }}
          >
            {description}
          </Box>
          <Box
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 700,
              fontSize: { xs: "1.6rem", sm: "2rem", md: "2.4rem" },
              lineHeight: { xs: "2rem", sm: "2.5rem", md: "3.6rem" },
            }}
          >
            {email}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
 
    <Box
      sx={{
        background: `linear-gradient(to right, #232526, #3A3C3E)`,
        width: "100%",
        minHeight: "100vh",
      }}
    >
      <Header color="black" />
      <Box
        sx={{
          color: "white",
          padding: { xs: "2rem", sm: "4rem", md: "4rem 6rem" },
          overflow: "auto",
          height: "100vh",
        }}
      >
	   <Helmet>
        <title>Contact Us - Battlesync</title>
        <meta name="description" content="Contact Us - Battlesync" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://battlesync.in/contact-us" />
      </Helmet>
        <Box
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: { xs: "1.8rem", sm: "2rem", md: "4rem" },
            lineHeight: { xs: "2.5rem", sm: "2.5rem", md: "4.2rem" },
          }}
        >
          Contact Us - Battlesync
        </Box>
        <Box
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 300,
            fontSize: { xs: "1.4rem", sm: "2rem", md: "3rem" },
            lineHeight: { xs: "2.5rem", sm: "2.5rem", md: "4rem" },
          }}
        >
          Let’s Connect!
        </Box>

        <Box
          sx={{
            margin: "3rem 0rem",
            display: "flex",
            flexDirection: { xs: "column", md: " row" },
            gap: { xs: "2rem", md: "12rem" },
          }}
        >
          <EmailContact
            title="Email Us"
            description="For any inquiries, please email us at:"
            email="BattleSync.gg@gmail.com"
            icon={Email}
          />
          <EmailContact
            title="Discord"
            description="Join our community:"
            email="Battle Sync"
            icon={Discord}
            link="https://discord.gg/fbpg3AjG"
          />
        </Box>
        <Box sx={{ margin: "3rem 0rem" }}>
          <Box sx={{ marginBottom: "2rem" }}>
            <Box
              sx={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 500,
                fontSize: { xs: "1.8rem", sm: "2rem", md: "3.2rem" },
                lineHeight: { xs: "2.5rem", sm: "2.5rem", md: "3.6rem" },
              }}
            >
              Social Media :{" "}
              <Box
                component="span"
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 300,
                  fontSize: { xs: "1.8rem", sm: "2rem", md: "2.4rem" },
                  lineHeight: { xs: "2.5rem", sm: "2.5rem", md: "3.6rem" },
                }}
              >
                Follow us and reach out via direct message on any of our
                platforms
              </Box>
            </Box>
          </Box>
          <Box>
            <Grid container spacing={4}>
              <Grid item xs={6} sm={4}>
                <EmailContact
                  description="Youtube"
                  email="BattleSync-gg"
                  icon={Youtube}
                  link="https://www.youtube.com/@BattleSync-gg"
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <EmailContact
                  description="Instagram"
                  email="BattleSync.gg"
                  icon={Instagram}
                  link="https://www.instagram.com/battlesync.gg"
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <EmailContact
                  description="Facebook"
                  email="BattleSync"
                  icon={Facebook}
                  link="https://www.facebook.com/profile.php?id=61568669413414"
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <EmailContact
                  description="Twitter"
                  email="Battle_Sync_gg"
                  icon={X}
                  link="https://x.com/battle_sync_gg"
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <EmailContact
                  description="Reddit"
                  email="BattleSync"
                  icon={Reddit}
                  link="https://www.reddit.com/r/BattleSync/"
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <EmailContact
                  description="Quora"
                  email="BattleSync"
                  icon={Quora}
                  link="https://battlesync.quora.com"
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: { xs: "1.6rem", sm: "2rem", md: "2.4rem" },
            lineHeight: { xs: "2rem", sm: "2.5rem", md: "3.6rem" },
          }}
        >
          Follow our social handles for the latest on esports tournaments,
          rewards, and events.
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default ContactUs;
