// ErrorPage.js
import React from "react";
import { Box, Typography } from "@mui/material";

const ErrorPage = () => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h1" sx={{ fontSize: "3rem", color: "#ff0000" }}>
        404 - Page Not Found
      </Typography>
      <Typography variant="body1" sx={{ color: "#BFBFBF", fontSize: "1.5rem" }}>
        Sorry, the page you're looking for does not exist.
      </Typography>
    </Box>
  );
};

export default ErrorPage;
