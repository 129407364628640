import { Box } from "@mui/material";
import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

function FullBolg({ setShowFullBlog, selectedBlog }) {
  return (
    <Box
      sx={{
        height: "100%",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: 6,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "grey",
          borderRadius: "10px",
        },
      }}
    >
      <Box
        sx={{
          fontFamily: "Poppins, sans-serif !important",
          fontWeight: 700,
          fontSize: { xs: "1rem", sm: "1rem", md: "1rem" },
          color: "white",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          background: "#3D3D3D",
          width: "min-content",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          padding: "0.6rem",
        }}
        onClick={() => setShowFullBlog(false)}
      >
        <KeyboardBackspaceIcon sx={{ margin: "0rem 0.2rem" }} /> Back
      </Box>

      <Box sx={{ margin: "5rem" }}>
        <Box
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: { xs: "2rem", sm: "2.4rem", md: "4rem" },
            color: "#FAFAFA",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            marginBottom: "1rem",
          }}
        >
          {selectedBlog.title}
        </Box>
        <Box
          sx={{
            height: "80%",
            marginBottom: "2rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={selectedBlog.image}
            alt="full blog"
            style={{
              height: "80%",
              width: "80%",
              objectFit: "cover",
              borderRadius: "1rem",
            }}
          />
        </Box>
        <Box
          sx={{
            color: "white",
            fontFamily: "Poppins, sans-serif",
            fontWeight: { xs: 300, sm: 300, md: 400 },
            fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
            marginTop: { xs: "0.7rem", sm: "1.8rem", md: "1.2rem" },
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
          dangerouslySetInnerHTML={{
            __html: selectedBlog.content
              .replace(
                /<table/g,
                '<table style="border: 2px solid white; border-collapse: collapse; width: 100%; word-wrap: break-word; overflow-wrap: break-word;"'
              )
              .replace(
                /<td/g,
                '<td style="border: 2px solid white; padding: 5px; text-align: left; word-wrap: break-word; overflow-wrap: break-word;"'
              )
              .replace(
                /<th/g,
                '<th style="border: 2px solid white; padding: 5px; text-align: left; word-wrap: break-word; overflow-wrap: break-word;"'
              ),
          }}
        />
      </Box>
      <Box sx={{ gap: "1rem", margin: "5rem" }}>
        <Box
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: { xs: "2rem", sm: "2.4rem", md: "2.4rem" },
            color: "#FAFAFA",
          }}
        >
          {" "}
          Tags:
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "0.5rem",
          }}
        >
          {selectedBlog.tags &&
            selectedBlog.tags.map((tag, index) => (
              <Box
                key={index}
                sx={{
                  color: "white",
                  background: "#535C68",
                  borderRadius: "0.7rem",
                  padding: "0.5rem 1rem",
                  alignItems: "center",
                  fontFamily: "PostNoBillsJaffna",
                  fontWeight: 800,
                  fontSize: {
                    xs: "1rem",
                    sm: "1.4rem",
                    md: "1.4rem",
                  },
                }}
              >
                {tag}
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
}

export default FullBolg;
