import { Box, Typography } from "@mui/material";
import React from "react";

function Chats() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "60vh",
      }}
    >
      <Box
        sx={{
          padding: "2rem 4rem",
          backgroundColor: "#FF5733",
          borderRadius: "0.8rem",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
        }}
      >
        <Typography
          sx={{
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 700,
            fontSize: "2.5rem",
            color: "#FFFFFF",
            textAlign: "center",
          }}
        >
          We are coming soon
        </Typography>
      </Box>
    </Box>
  );
}

export default Chats;
