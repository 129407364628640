import { Alert, Box, Snackbar } from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomInput, {
  CustomInputWithoutIcon,
} from "../shared/CustomInput/CustomInput";
import { useSelector } from "react-redux";
import Button from "../shared/Button/Button";
import axios from "axios";

function Support() {
  const { user } = useSelector((state) => state.dashboard);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const basUrl = `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}`;
  const [formValues, setFormValues] = useState({
    username: "",
    subject: "",
    message: "",
  });

  useEffect(() => {
    if (user?.username) {
      setFormValues((prevValues) => ({
        ...prevValues,
        username: user.username,
      }));
    }
  }, [user?.username]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${basUrl}/feedback`, {
        username: formValues.username,
        subject: formValues.subject,
        feedback: formValues.message,
      });
      setSnackbarMessage("Feedback submitted successfully!");
      setSnackbarSeverity("success");
      setFormValues({
        username: "",
        subject: "",
        message: "",
      }); // Reset form values
    } catch (error) {
      setSnackbarMessage("Failed to submit feedback.");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  return (
    <Box
      sx={{
        height: "auto",
        overflowY: "auto",
        display: "flex",
        justifyContent: "center",
        "&::-webkit-scrollbar": {
          width: 6,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "grey",
          borderRadius: "10px",
        },
      }}
    >
      <Box
        sx={{
          border: "0.2rem solid #949494",
          borderRadius: "2rem",
          margin: "2rem",
          padding: "2rem",
          width: "80%",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: { xs: "2.4rem", sm: "3.6rem", md: "4rem" },
            lineHeight: { xs: "3.6rem", sm: "5.4rem", md: "6rem" },
            color: "white",
          }}
        >
          FeedBack Form
        </Box>
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <CustomInput
            name="username"
            value={formValues.username}
            onChange={handleChange}
            placeholder={user?.username}
            required
            bordercolor="0.2rem solid #949494"
            color="white"
            disabled="disabled"
          />
          <CustomInputWithoutIcon
            name="subject"
            value={formValues.subject}
            onChange={handleChange}
            placeholder="Subject"
            bordercolor="0.2rem solid #949494"
            color="white"
            required
          />
          <CustomInputWithoutIcon
            name="message"
            value={formValues.message}
            onChange={handleChange}
            placeholder="Message"
            bordercolor="0.2rem solid #949494"
            color="white"
            height="14rem"
            required
          />
          <Button
            text="Submit"
            type="submit"
            width="40%"
            marginTop="1rem auto"
          />
        </form>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: "100%", fontSize: "1.4rem" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Support;
