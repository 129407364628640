import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../services/api";

export const profileImage = createAsyncThunk(
  "profileImage/fetchUser",
  async (username, { rejectWithValue }) => {
    try {
      const response = await api("get", `/profile-picture/${username}`);
      return response;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const profileImageSlice = createSlice({
  name: "profileImage",
  initialState: {
    isLoading: false,
    user: null,
    status: "idle",
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(profileImage.pending, (state) => {
        state.profileImageisLoading = true;
        state.profileImagestatus = "loading";
      })
      .addCase(profileImage.fulfilled, (state, action) => {
        state.profileImageisLoading = false;
        state.status = "succeeded";
        state.profileImageuser = action.payload;
      })
      .addCase(profileImage.rejected, (state, action) => {
        state.profileImageisLoading = false;
        state.profileImagestatus = "failed";
        state.profileImageerror = action.payload;
      });
  },
});

export default profileImageSlice.reducer;
