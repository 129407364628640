import { jwtDecode } from "jwt-decode";

export const isAuthenticated = () => {
  if (typeof window === "undefined") {
    return false;
  }
  const authToken = localStorage.getItem("authToken");
  if (authToken) {
    return JSON.parse(authToken);
  } else {
    return false;
  }
};

// export const isAuthenticated = () => {
//   const authToken = localStorage.getItem("authToken");
//   if (!authToken) {
//     return false;
//   }

//   try {
//     const { exp } = jwtDecode(authToken);
//     if (exp < Date.now() / 1000) {
//       localStorage.removeItem("authToken");
//       return false;
//     }
//     return true;
//   } catch (error) {
//     console.error("Invalid token", error);
//     localStorage.removeItem("authToken");
//     return false;
//   }
// };


export const getMyId = () => {
  const authToken = localStorage.getItem("authToken");
  if (authToken) {
    const decoded = jwtDecode(JSON.parse(authToken));
    return decoded.userId;
  }
};

export const getMyRole = () => {
  const authToken = localStorage.getItem("authToken");
  if (authToken) {
    const decoded = jwtDecode(JSON.parse(authToken));
    return decoded.role;
  }
};

export const authenticate = (authToken, next) => {
  if (typeof window !== "undefined") {
    if (authToken) {
      localStorage.setItem("authToken", JSON.stringify(authToken));
      if (typeof next === "function") {
        next();
      }
    }
  }
};

// export const logout = (next) => {
//   if (typeof window !== "undefined") {
//     localStorage.clear();
//     if (typeof next === "function") {
//       next();
//     }
//   }
// };

export const logout = () => {
  if (typeof window !== "undefined") {
    localStorage.clear();
    window.location.href = "/";
  }
};
