import React, { useEffect, useState } from "react";
import { Box, Divider } from "@mui/material";
import TournamentCards from "../shared/Card/tournamentCards";
import TournamentBG from "../Assests/Image/TournamentBG.svg";
import TournamentLayout from "./TournamentsRoaster/TournamentLayout";
import { useSelector } from "react-redux";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import axios from "axios";
import trophy from "../Assests/Dashboard/blog/trophy.svg";
import squad from "../Assests/Dashboard/blog/squad.svg";

function Tournaments() {
  const [showTournaments, setShowTournaments] = useState(false);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const { user, isLoading, error } = useSelector((state) => state.dashboard);
  const { profileImageuser } = useSelector((state) => state.profileImage);
  const [tournaments, setTournaments] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [allTournaments, setAllTournaments] = useState([]);

  const userImage = profileImageuser
    ? `data:image/png;base64,${profileImageuser?.image}`
    : null;

  const basUrl = `${process.env.REACT_APP_CMS_PROTOCOL}${process.env.REACT_APP_CMS_HOST}${process.env.REACT_APP_CMS_PORT}`;
  const localPort = `${process.env.REACT_APP_CMS_PROTOCOL}${process.env.REACT_APP_CMS_HOST}${process.env.REACT_APP_PORT}`;

  useEffect(() => {
    const fetchTournaments = async () => {
      try {
        const getResponse = await axios.get(`${basUrl}/api/tournaments`);
        setTournaments(getResponse.data);
      } catch (error) {
        console.error("Failed to fetch tournaments:", error);
      }
    };

    fetchTournaments();
  }, []);

  useEffect(() => {
    const processedTournaments = new Set();

    const fetchExistingTournaments = async () => {
      try {
        // Fetch all existing tournaments
        const existingTournamentsResponse = await axios.get(
          `${localPort}/get_all_tournaments`
        );
        const existingTournaments = existingTournamentsResponse.data;
        setAllTournaments(existingTournaments?.data);

        const filteredTournaments = tournaments.filter((tournament) => {
          return !existingTournaments?.data.some(
            (existingTournament) =>
              existingTournament.upComingDate === tournament.upComingDate
          );
        });

        if (filteredTournaments.length > 0) {
          for (const tournament of filteredTournaments) {
            if (!processedTournaments.has(tournament.id)) {
              const postResponse = await axios.post(
                `${localPort}/create_tournament`,
                {
                  tournament_name: tournament.title,
                  num_of_slot: tournament.playerSlot,
                  cms_tournament_id: tournament.id,
                  upComingDate: tournament.upComingDate,
                }
              );
              processedTournaments.add(tournament.id);
            }
          }
        }
      } catch (error) {
        console.error(
          "Failed to fetch existing tournaments or create new ones:",
          error
        );
      }
    };

    if (tournaments.length > 0) {
      fetchExistingTournaments();
    }
  }, [tournaments]);

  const showTournament = async (tournament) => {
    try {
      const groupResponse = await axios.get(
        `${localPort}/tournaments/${tournament?.allTournamentData?.id}`
      );
      setSelectedSlots(groupResponse.data);
      // const slotResponse = await axios.get(
      //   `${localPort}/get_available_slot/${tournament?.allTournamentData?.id}`
      // );
      // setAvailableSlots(slotResponse.data);
      setSelectedTournament(tournament);
      setShowTournaments(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function formatTournamentDate(dateString) {
    const date = new Date(dateString); // Parse the date string
    const options = {
      day: "2-digit",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // To get AM/PM format
    };

    return date.toLocaleDateString("en-US", options).replace(",", ""); // Format and clean up
  }

  const formatWinPrice = (price) => {
    return price >= 1000 ? `${(price / 1000).toFixed(0)}k` : price;
  };

  return (
    <Box
      sx={{
        height: "100%",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: 6,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "grey",
          borderRadius: "10px",
        },
      }}
    >
      {!showTournaments ? (
        <TournamentCards
          item={tournaments}
          showTournaments={showTournament}
          allTournaments={allTournaments}
          showArrowButton={true}
        />
      ) : (
        <Box
          sx={{
            height: "100%",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: 6,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "grey",
              borderRadius: "10px",
            },
          }}
        >
          <Box
            sx={{
              fontFamily: "Poppins, sans-serif !important",
              fontWeight: 700,
              fontSize: { xs: "1rem", sm: "1rem", md: "1rem" },
              lineHeight: { xs: "1.5rem", sm: "1.5rem", md: "1.5rem" },
              color: "white",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              background: "#3D3D3D",
              width: "min-content",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              padding: "0.6rem",
            }}
            onClick={() => setShowTournaments(false)}
          >
            <KeyboardBackspaceIcon sx={{ margin: "0rem 0.2rem" }} /> Back
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              margin: "1.9rem 0.8rem",
              gap: "1rem",
            }}
          >
            <Box
              component="img"
              src={selectedTournament?.icon}
              alt="gameImg"
              sx={{
                height: { xs: "24rem", sm: "14.6rem", md: "18.8rem" },
                width: { xs: "100%", sm: "25.6rem", md: "30.3rem" },
                objectFit: "cover",
                borderRadius: "0.9rem",
              }}
            />
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  fontFamily: "Poppins, sans-serif !important",
                  fontWeight: 500,
                  fontSize: { xs: "1.4rem", sm: "1.4rem", md: "1.6rem" },
                  lineHeight: { xs: "2.1rem", sm: "2.1rem", md: "2.4rem" },
                  color: "white",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {formatTournamentDate(selectedTournament.upComingDate)}
              </Box>
              <Box
                sx={{
                  fontFamily: "Poppins, sans-serif !important",
                  fontWeight: 600,
                  fontSize: { xs: "2rem", sm: "2rem", md: "2.4rem" },
                  lineHeight: { xs: "3rem", sm: "3rem", md: "3.6rem" },
                  color: "white",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  flexWrap: "wrap",
                }}
              >
                {selectedTournament.title}
              </Box>{" "}
              <Box
                sx={{
                  fontFamily: "Poppins, sans-serif !important",
                  fontWeight: 500,
                  fontSize: { xs: "1.2rem", sm: "1.2rem", md: "1.4rem" },
                  lineHeight: { xs: "1.8rem", sm: "1.8rem", md: "2.1rem" },
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  color: "#CBCBCC",
                }}
              >
                {selectedTournament.description}
              </Box>
              <Divider
                sx={{ my: 2, backgroundColor: "#8B8B8B", width: "100%" }}
              />
              <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                <Box
                  sx={{
                    display: "inlineflex",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      fontFamily: "Poppins, sans-serif !important",
                      fontWeight: 500,
                      fontSize: { xs: "1.4rem", sm: "1.4rem", md: "1.6rem" },
                      lineHeight: {
                        xs: "2.1rem",
                        sm: "2.1rem",
                        md: "2.4rem",
                      },
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,

                      color: "#CBCBCC",
                    }}
                  >
                    Win Price
                  </Box>
                  <Box>
                    <img
                      src={trophy}
                      alt="trophy"
                      style={{
                        height: {
                          xs: "1.5rem",
                          sm: "1.5rem",
                          md: "2rem",
                        },
                        width: {
                          xs: "1.5rem",
                          sm: "1.5rem",
                          md: "2rem",
                        },
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      fontFamily: "Poppins, sans-serif !important",
                      fontWeight: 700,
                      fontSize: { xs: "1.2rem", sm: "2rem", md: "1.4rem" },
                      lineHeight: {
                        xs: "1.8rem",
                        sm: "2.5rem",
                        md: "2.1rem",
                      },
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",

                      color: "#CBCBCC",
                    }}
                  >
                    {formatWinPrice(selectedTournament?.winPrice)}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "inlineflex",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      fontFamily: "Poppins, sans-serif !important",
                      fontWeight: 500,
                      fontSize: { xs: "1.4rem", sm: "1.4rem", md: "1.6rem" },
                      lineHeight: {
                        xs: "2.1rem",
                        sm: "2.1rem",
                        md: "2.4rem",
                      },
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,

                      color: "#CBCBCC",
                    }}
                  >
                    Player Slot
                  </Box>
                  <Box>
                    <img
                      src={squad}
                      alt="trophy"
                      style={{
                        height: {
                          xs: "1.5rem",
                          sm: "1.5rem",
                          md: "2rem",
                        },
                        width: {
                          xs: "1.5rem",
                          sm: "1.5rem",
                          md: "2rem",
                        },
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      fontFamily: "Poppins, sans-serif !important",
                      fontWeight: 700,
                      fontSize: { xs: "1.2rem", sm: "2rem", md: "1.4rem" },
                      lineHeight: {
                        xs: "1.8rem",
                        sm: "2.5rem",
                        md: "2.1rem",
                      },
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",

                      color: "#CBCBCC",
                    }}
                  >
                    {selectedTournament?.playerSlot}v
                    {selectedTournament?.playerSlot}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)), url(${TournamentBG})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "100%",
            }}
          >
            <TournamentLayout
              selectedTournament={selectedTournament}
              userData={user}
              userImage={userImage}
              availableSlots={availableSlots}
              selectedSlots={selectedSlots}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Tournaments;
