import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const basUrl = `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}`;
// Define the async thunk
export const signUpUser = createAsyncThunk(
  "signup/signUpUser",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${basUrl}/signUp`, formData);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

// Create the slice
const signupSlice = createSlice({
  name: "signUp",
  initialState: {
    user: null,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signUpUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(signUpUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(signUpUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default signupSlice.reducer;
