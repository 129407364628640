import OutlinedInput from "@mui/material/OutlinedInput";
import KeySharpIcon from "@mui/icons-material/KeySharp";
import KeyOffSharpIcon from "@mui/icons-material/KeyOffSharp";
import { IconButton, InputAdornment } from "@mui/material";
import React, { useState } from "react";

function CustomPassword({
  placeholder,
  required,
  name,
  onChange,
  value,
  bordercolor,
  color,
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => setShowPassword(!showPassword);

  return (
    <div>
      <OutlinedInput
        sx={{
          padding: "0 1rem",
          margin: "0.5rem auto",
          borderRadius: "4.6rem",
          border: bordercolor ? bordercolor : "0.2rem solid #36769D",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& .MuiInputBase-input::placeholder": {
            fontFamily: "Poppins, sans-serif",
            fontWeight: 300,
            fontSize: { xs: "1.4rem", sm: "2rem", md: "2.4rem" },
            lineHeight: { xs: "2.1rem", sm: "3rem", md: "3.6rem" },
            color: color ? color : "#423F63",
            opacity: 1,
          },
          "& .MuiInputBase-input": {
            fontFamily: "Poppins, sans-serif",
            fontWeight: 300,
            fontSize: { xs: "1.4rem", sm: "2rem", md: "2.4rem" },
            lineHeight: { xs: "2.1rem", sm: "3rem", md: "3.6rem" },
            color: color ? color : "#423F63",
          },
          height: "4.5rem",
          width: "100%",
        }}
        type={showPassword ? "text" : "password"}
        size="small"
        onChange={onChange}
        name={name}
        value={value}
        placeholder={placeholder}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
              onClick={handleShowPassword}
            >
              {!showPassword ? (
                <KeySharpIcon
                  sx={{
                    height: "3.5rem",
                    width: "3.5rem",
                    color: color ? color : "#423F63",
                  }}
                />
              ) : (
                <KeyOffSharpIcon
                  sx={{
                    height: "3.5rem",
                    width: "3.5rem",
                    color: color ? color : "#423F63",
                  }}
                />
              )}
            </IconButton>
          </InputAdornment>
        }
        inputProps={{
          style: {
            fontFamily: "Poppins, sans-serif",
            fontWeight: 300,
            fontSize: { xs: "1.4rem", sm: "2rem", md: "2.4rem" },
            lineHeight: { xs: "2.1rem", sm: "3rem", md: "3.6rem" },
            color: color ? color : "#423F63",
          },
        }}
      />
    </div>
  );
}

export default CustomPassword;
