import { Box, Button, Typography, Snackbar, Alert } from "@mui/material";
import React, { useState } from "react";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { useDispatch, useSelector } from "react-redux";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { postCommunity } from "../redux/features/friends/postCommunity";

function CreatePost() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.dashboard);
  const { profileImageuser } = useSelector((state) => state.profileImage);
  const imageSrc = profileImageuser
    ? `data:image/png;base64,${profileImageuser?.image}`
    : null;

  const [formValues, setFormValues] = useState({
    description: "",
    username: user?.username,
    photo: null,
  });

  const [imagePreview, setImagePreview] = useState(null);

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formValues.description && !formValues.photo) {
      alert("Please add some text or an image to post.");
      return;
    }

    const formData = new FormData();
    for (const key in formValues) {
      formData.append(key, formValues[key]);
    }

    dispatch(postCommunity(formData))
      .then(() => {
        setSnackbarMessage("Post created successfully!");
        setSnackbarSeverity("success");
      })
      .catch(() => {
        setSnackbarMessage("Failed to create post.");
        setSnackbarSeverity("error");
      })
      .finally(() => {
        setSnackbarOpen(true);
        setFormValues((prevValues) => ({
          ...prevValues,
          description: "",
          photo: null,
        }));
        setImagePreview(null);
      });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.size > 1048576) {
        setSnackbarMessage("Image size exceeds 1 MB limit.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }

      setFormValues({
        ...formValues,
        photo: file,
      });

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        marginTop: "3rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: 6,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "grey",
          borderRadius: "10px",
        },
      }}
    >
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <Box
          sx={{
            width: { xs: "80%", sm: "60%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            border: "1px solid #949494",
            borderRadius: "0.8rem",
            padding: "0.6rem 1rem",
            gap: "1rem",
            margin: "0 auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box
                component="img"
                src={imageSrc}
                alt="userImg"
                sx={{
                  width: "6rem",
                  height: "6rem",
                  borderRadius: "50%",
                  objectFit: "cover",
                  display: "block",
                  aspectRatio: "1 / 1",
                }}
              />
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 600,
                    fontSize: { xs: "1rem", sm: "1.6rem", md: "1.6rem" },
                    lineHeight: { xs: "1.5rem", sm: "2.4rem", md: "2.4rem" },
                    color: "white",
                    alignItems: "center",
                  }}
                >
                  {user?.username}
                </Typography>
              </Box>
            </Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!formValues.description && !formValues.photo}
              sx={{
                background: "#686DE0",
                borderRadius: "1.2rem",
                padding: "0.3rem 0.9rem",
                alignContent: "center",
              }}
            >
              Post
              <AddCircleOutlineRoundedIcon />
            </Button>
          </Box>
          <Box
            component="textarea"
            name="description"
            value={formValues.description}
            onChange={handleChange}
            placeholder="Write what’s on your mind..."
            sx={{
              border: "1px solid #949494",
              borderRadius: "0.8rem",
              padding: "1rem",
              color: "#fff",
              fontFamily: "Poppins, sans-serif",
              fontWeight: 500,
              fontSize: { xs: "1.2rem", sm: "1.3rem", md: "1.3rem" },
              lineHeight: { xs: "1.8rem", sm: "2rem", md: "2rem" },
              backgroundColor: "#333",
              height: "5rem",
              resize: "none",
            }}
          />
          <Box
            sx={{
              border: "1px solid #949494",
              borderRadius: "0.8rem",
              padding: "1rem",
              backgroundColor: "#333",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            {imagePreview ? (
              <Box
                component="img"
                src={imagePreview}
                alt="uploaded-preview"
                sx={{
                  marginTop: "1rem",
                  width: "100%",
                  borderRadius: "0.5rem",
                  objectFit: "cover",
                }}
              />
            ) : (
              <Button component="label" sx={{ color: "#232526" }}>
                <AddPhotoAlternateIcon
                  sx={{ height: "30rem", width: "30rem", color: "#232526" }}
                />
                <input
                  type="file"
                  hidden
                  onChange={handleImageUpload}
                  accept="image/*"
                />
              </Button>
            )}
          </Box>
        </Box>
      </form>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: "100%", fontSize: "1.4rem" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default CreatePost;
