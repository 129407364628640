import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../services/api";

export const pendingRequest = createAsyncThunk(
  "pendingRequest",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await api("post", "/pending-request", formData);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const pendingRequestSlice = createSlice({
  name: "pendingRequest",
  initialState: {
    isLoading: false,
    user: null,
    status: "idle",
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(pendingRequest.pending, (state) => {
        state.pendingRequestisLoading = true;
        state.pendingRequeststatus = "loading";
      })
      .addCase(pendingRequest.fulfilled, (state, action) => {
        state.pendingRequestisLoading = false;
        state.pendingRequeststatus = "succeeded";
        state.pendingRequestuser = action.payload.users;
      })
      .addCase(pendingRequest.rejected, (state, action) => {
        state.pendingRequestisLoading = false;
        state.pendingRequeststatus = "failed";
        state.pendingRequesterror = action.payload;
      });
  },
});

export default pendingRequestSlice.reducer;
