import React from "react";
import { Box } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

function InternalButton({ text, type, onClick }) {
  return (
    <Box
      component="button"
      type={type}
      sx={{
        background: "#535C68",
        color: "white",
        padding: "0rem 0.5rem",
        borderRadius: "0.7rem",
        border: "none",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 400,
        fontSize: { xs: "1.4rem", sm: "2rem", md: "1.2rem" },
        lineHeight: { xs: "2.1rem", sm: "3rem", md: "2.4rem" },
        textAlign: "center",
        cursor: "pointer",
        width: "fit-content",
        alignItems: "center",
        display: "flex",
      }}
      onClick={onClick}
    >
      {text}
      <ArrowRightIcon />
    </Box>
  );
}

export default InternalButton;
