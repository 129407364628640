import { Box, Divider } from "@mui/material";
import React from "react";
import trophy from "../../Assests/Dashboard/blog/trophy.svg";
import squad from "../../Assests/Dashboard/blog/squad.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function TournamentCards({
  item,
  showTournaments,
  allTournaments,
  showArrowButton,
}) {
  function formatTournamentDate(dateString) {
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleDateString("en-US", options).replace(",", "");
  }

  const combinedData = (item || []).map((tournament, index) => ({
    ...tournament,
    allTournamentData:
      allTournaments && allTournaments[index] ? allTournaments[index] : [],
  }));

  const formatWinPrice = (price) => {
    return price >= 1000 ? `${(price / 1000).toFixed(0)}k` : price;
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-evenly",
        flexDirection: "row",
        gap: "1rem",
        flexWrap: "wrap",
        padding: "2rem 2rem 0rem 2rem",
      }}
    >
      {combinedData.map((data, idx) => (
        <Box
          key={idx}
          sx={{
            border: "1px solid #8B8B8B",
            borderRadius: "1rem",
            padding: "0.5rem",
            margin: "0.5rem",
            backgroundColor: "#171717",
            color: "white",
            textAlign: "left",
            flex: "1 1 calc(23% - 1rem)",
            minWidth: "22rem",
            maxWidth: "calc(23% - 1rem)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              borderRadius: "1rem",
            }}
          >
            <Box
              component="img"
              src={data?.icon}
              alt="gameImg"
              sx={{
                width: "100%",
                height: { xs: "9.4rem", sm: "12rem", md: "13rem" },
                objectFit: "cover",
                borderRadius: "0.9rem",
              }}
            />
          </Box>

          <Box sx={{ padding: "1rem 0 0 1rem", minHeight: "1rem" }}>
            <Box
              sx={{
                fontFamily: "Poppins, sans-serif !important",
                fontWeight: 500,
                fontSize: "0.8rem",
                lineHeight: "1.2rem",
              }}
            >
              {formatTournamentDate(data.upComingDate)}
            </Box>
            <Box
              sx={{
                fontFamily: "Poppins, sans-serif !important",
                fontWeight: 600,
                fontSize: "1.8rem",
                lineHeight: "2.7rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
              }}
            >
              {data.title}
            </Box>
            <Box
              sx={{
                fontFamily: "Poppins, sans-serif !important",
                fontWeight: 500,
                fontSize: { xs: "0.6rem", sm: "1rem", md: "1rem" },
                lineHeight: { xs: "0.9rem", sm: "1.5rem", md: "1.5rem" },
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                width: "100%",
                color: "#CBCCCC",
              }}
            >
              {data.description}
            </Box>
          </Box>

          <Divider sx={{ my: 2, backgroundColor: "#8B8B8B" }} />
          <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
            <Box>
              <Box>Win Price</Box>
              <Box sx={{ display: "flex" }}>
                <img
                  src={trophy}
                  alt="trophy"
                  style={{ height: "1.5rem", width: "1.5rem" }}
                />
                {formatWinPrice(data.winPrice)}
              </Box>
            </Box>
            <Box>
              <Box>Player Slot</Box>
              <Box sx={{ display: "flex" }}>
                <img
                  src={squad}
                  alt="trophy"
                  style={{ height: "1.5rem", width: "1.5rem" }}
                />
                {data.playerSlot}v{data.playerSlot}
              </Box>
            </Box>
            {showArrowButton === true && (
              <Box
                sx={{
                  alignItems: "center",
                  background: "#686DE0",
                  padding: "0.5rem",
                  borderRadius: "6rem",
                  cursor: "pointer",
                }}
                onClick={() => showTournaments(data)}
              >
                <ArrowForwardIcon />
              </Box>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default TournamentCards;
