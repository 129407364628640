import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Menu, Sidebar, MenuItem } from "react-pro-sidebar";
import userImg from "../Assests/HomeBackground/Bg1.svg";
import GroupsIcon from "@mui/icons-material/Groups";
import { useDispatch, useSelector } from "react-redux";
import { allFriend } from "../redux/features/friends/allFriendsSlice";

const Item = ({ title, icon, selected, setSelected }) => {
  return (
    <MenuItem
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "1rem",
      }}
      active={selected === title}
      onClick={() => setSelected(title)}
      icon={
        <Box
          component="img"
          src={icon}
          alt={title}
          sx={{
            width: "6rem",
            height: "6rem",
            borderRadius: "50%",
            objectFit: "cover",
            display: "block",
            aspectRatio: "1 / 1",
          }}
        />
      }
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter, sans-serif",
            fontWeight: 600,
            fontSize: "1.6rem",
            lineHeight: "2rem",
            color: "white",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {title}
        </Typography>
      </Box>
    </MenuItem>
  );
};

const FriendsList = ({ friendName, icon, selected, setSelected }) => {
  return (
    <MenuItem
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "0.5rem",
      }}
      active={selected === friendName}
      onClick={() => setSelected(friendName)}
      icon={
        <Box
          component="img"
          src={icon}
          alt={friendName}
          onError={(e) => {
            console.error(`Failed to load image at ${e.target.src}`); // Log error
            e.target.src = userImg;
          }}
          sx={{
            width: "4.5rem",
            height: "4.5rem",
            borderRadius: "50%",
            objectFit: "cover",
            display: "block",
            aspectRatio: "1 / 1",
          }}
        />
      }
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter, sans-serif",
            fontWeight: 600,
            fontSize: "1.6rem",
            lineHeight: "2rem",
            color: "white",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {friendName}
        </Typography>
      </Box>
    </MenuItem>
  );
};

function Friends({ collapsed, toggleSidebar, userDetails }) {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState("Dashboard");
  const [friendImages, setFriendImages] = useState([]);
  const { allFrienduser, isLoading, error } = useSelector(
    (state) => state.allFriend
  );
  const { profileImageuser } = useSelector((state) => state.profileImage);

  const basUrl = `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}`;

  const NAMES = `${userDetails?.first_name} ${userDetails?.last_name}` || "";
  const userImage = `data:image/png;base64,${profileImageuser?.image}`;

  useEffect(() => {
    if (userDetails?.username) {
      dispatch(allFriend({ username: userDetails?.username }));
    }
  }, [dispatch, userDetails?.username]);

  useEffect(() => {
    if (allFrienduser) {
      const validateImages = async () => {
        const updatedFriends = await Promise.all(
          allFrienduser.map(async (friend) => {
            try {
              const response = await fetch(
                `${basUrl}/profile-picture/${friend.username}`
              );
              const data = await response.json();
              if (response.ok) {
                return {
                  name: friend.username,
                  icon: `data:image/png;base64,` + data.image,
                };
              } else {
                return { name: friend.username, icon: userImg };
              }
            } catch (error) {
              console.error(
                `Error fetching image for ${friend.username}:`,
                error
              );
              return { name: friend.username, icon: userImg };
            }
          })
        );
        setFriendImages(updatedFriends);
      };

      validateImages();
    }
  }, [allFrienduser]);

  // Handle the loading, error, and fetched data states
  const friends = isLoading
    ? [{ name: "Loading...", icon: userImg }]
    : error
    ? [{ name: "Error loading friends", icon: userImg }]
    : friendImages;

  return (
    <Box sx={{ borderLeft: "0.5px solid #454545" }}>
      <Box
        sx={{
          position: "sticky",
          display: "flex",
          height: "100vh",
          top: 0,
          bottom: 0,
          width: "100%",
          zIndex: 10000,
          "& .menu-icon": {
            color: "#BFBFBF",
            backgroundColor: "transparent !important",
          },
          "& .menu-anchor": {
            color: "yellow",
            backgroundColor: "transparent !important",
          },
          // "& .menu-item:hover": {
          //   color: "white",
          //   backgroundColor: "#181818",
          // },
          // "& .menu-item.active": {
          //   color: "white",
          //   backgroundColor: "#686DE0",
          // },
        }}
      >
        <Sidebar
          breakPoint="md"
          backgroundColor={"#535C68"}
          borderRadius={"1.8rem"}
          collapsed={collapsed}
          style={{
            borderRight: "none",
            margin: "1rem",
            borderRadius: "1.8rem !important",
          }}
        >
          <Menu iconshape="square">
            <Box sx={{ padding: "1rem 0.5rem" }}>
              <Item
                title={NAMES}
                icon={userImage}
                selected={selected}
                setSelected={setSelected}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "1rem 0",
              }}
            >
              <GroupsIcon
                sx={{
                  color: "white",
                  height: "4rem",
                  width: "4rem",
                }}
              />
            </Box>
            {/* This is for the friends list */}
            <Box sx={{ padding: "1rem 0.5rem" }}>
              {friends.map((friend, idx) => (
                <FriendsList
                  key={idx}
                  friendName={friend.name}
                  icon={friend.icon}
                  selected={selected}
                  setSelected={setSelected}
                />
              ))}
            </Box>
          </Menu>
        </Sidebar>
      </Box>
    </Box>
  );
}

export default Friends;
