import { configureStore } from "@reduxjs/toolkit";
import signupReducer from "../features/signup/signUpSlice";
import loginReducer from "../features/login/loginSlice";
import authReducer from "../features/auth/authSlice";
import dashboardReducer from "../features/dashboard/dashboardSlice";
import searchFriendReducer from "../features/friends/friendSlice";
import requestFriendReducer from "../features/friends/requestSlice";
import allFriendSliceReducer from "../features/friends/allFriendsSlice";
import pendingRequestReducer from "../features/friends/pendingRequestSlice";
import profileImageReducer from "../features/dashboard/profile_imageSlice";
import updateProfileReducer from "../features/dashboard/updateProfile";
import postCommunityReducer from "../features/friends/postCommunity";
import getCommunityReducer from "../features/friends/getCommunity";
import blogReducer from "../features/blog/blogSlice";
import acceptendingRequestReducer from "../features/friends/acceptPendingRequest";
import RejectPendingRequestReducer from "../features/friends/RejectPendingRequest";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    signup: signupReducer,
    login: loginReducer,
    dashboard: dashboardReducer,
    searchFriend: searchFriendReducer,
    requestFriend: requestFriendReducer,
    allFriend: allFriendSliceReducer,
    pendingRequest: pendingRequestReducer,
    profileImage: profileImageReducer,
    updateProfile: updateProfileReducer,
    postCommunity: postCommunityReducer,
    getCommunity: getCommunityReducer,
    blog: blogReducer,
    acceptendingRequest: acceptendingRequestReducer,
    RejectPendingRequest: RejectPendingRequestReducer,
  },
});
