import { Box } from "@mui/material";
import React from "react";
import bg1 from "../Assests/HomeBackground/Bg1.svg";
import "../style/fontStyle.css";

function Home() {
  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${bg1})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        height: { xs: "40rem", sm: "80rem", md: "100vh" },
        position: "relative",
        overflow: "hidden",
        textAlign: "center",
        color: "white",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          fontSize: { xs: "2.4rem", sm: "5rem", md: "9.6rem" },
          lineHeight: { xs: "2.5rem", sm: "5rem", md: "9.6rem" },
        }}
        className="textNormal"
      >
        IT’S NOT GG
        <br /> TILL IT’S GG FOR EVERYONE
      </Box>
    </Box>
  );
}

export default Home;
