import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../services/api";

export const dashboard = createAsyncThunk(
  "dashboard/fetchUser",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api("get", "/dashboard");
      return response.user;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    isLoading: false,
    user: null,
    status: "idle",
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(dashboard.pending, (state) => {
        state.isLoading = true;
        state.status = "loading";
      })
      .addCase(dashboard.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(dashboard.rejected, (state, action) => {
        state.isLoading = false;
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default dashboardSlice.reducer;
