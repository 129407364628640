import { Box } from "@mui/material";
import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import AboutUs from "../pages/AboutUs";
import { Helmet } from "react-helmet";

function AboutUss() {
  return (
  
    <Box
      sx={{
        background: `linear-gradient(to right, #262829, #393B3D)`,
        width: "100%",
        minHeight: "100vh",
      }}
    >
	<Helmet>
        <title>About Us - Battlesync</title>
        <meta name="description" content="About Us - Battlesync" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://battlesync.in/about-us" />
      </Helmet>
      <Header color="black" />

      <AboutUs show={false} />
      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "block" },
        }}
      >
        <Footer />
      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          display: { xs: "block", sm: "block", md: "none" },
        }}
      >
        <Footer />
      </Box>
    </Box>
  );
}

export default AboutUss;
