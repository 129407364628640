import React, { useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import Windows from "../../Assests/games/Windows.svg";
import Android from "../../Assests/games/Android.svg";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { FreeMode, Pagination, Navigation } from "swiper/modules";
import { Box, IconButton } from "@mui/material";

function CustomCarousel({ items }) {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current) {
      const swiperInstance = swiperRef.current.swiper;
      swiperInstance.params.navigation.prevEl = prevRef.current;
      swiperInstance.params.navigation.nextEl = nextRef.current;

      swiperInstance.navigation.destroy();
      swiperInstance.navigation.init();
      swiperInstance.navigation.update();
    }
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        width: { xs: "90%", sm: "80%" },
        margin: "auto",
      }}
    >
      <IconButton
        ref={prevRef}
        sx={{ ...buttonStyle, left: { xs: "-20px", sm: "-30px", md: "-50px" } }}
      >
        <WestIcon />
      </IconButton>
      <IconButton
        ref={nextRef}
        sx={{
          ...buttonStyle,
          right: { xs: "-20px", sm: "-30px", md: "-50px" },
        }}
      >
        <EastIcon />
      </IconButton>

      <Swiper
        ref={swiperRef}
        freeMode={true}
        slidesPerView={3}
        spaceBetween={30}
        pagination={false}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        modules={[FreeMode, Pagination, Navigation]}
        className="mySwiper"
      >
        {items.map((data, idx) => (
          <SwiperSlide key={idx}>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                borderRadius: "1rem",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  backgroundImage: `url(${data.image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "100%",
                  minHeight: { xs: "6rem", sm: "12rem", md: "18rem" },
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(52, 52, 52, 0.6)",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                }}
              >
                <Box
                  sx={{
                    fontFamily: "Rubik Wet Paint, sans-serif",
                    fontWeight: 400,
                    fontSize: { xs: "1rem", sm: "1.5rem", md: "3.2rem" },
                    lineHeight: { xs: "1.1rem", sm: "1.7rem", md: "3.8rem" },
                    textAlign: "center",
                  }}
                >
                  {data.gameTitle.toUpperCase()}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#202020",
                    width: "fit-content",
                    margin: {
                      xs: "0.5rem auto",
                      sm: "1rem auto",
                      md: "2rem auto",
                    },
                    padding: { xs: "0rem 0.2rem", sm: "0.5rem" },
                    borderRadius: "1.8rem",
                  }}
                >
                  {data.device.map((deviceData, deviceIdx) => (
                    <Box key={deviceIdx} sx={{ padding: "0.2rem" }}>
                      {deviceData.item === "pc" && (
                        <Box
                          component="img"
                          src={Windows}
                          alt="Windows device logo"
                          sx={{
                            margin: "auto",
                            maxHeight: { xs: "1rem", sm: "1.5rem", md: "2rem" },
                          }}
                        />
                      )}
                      {deviceData.item === "mobile" && (
                        <Box
                          component="img"
                          src={Android}
                          alt="Android device logo"
                          sx={{
                            margin: "auto",
                            maxHeight: { xs: "1rem", sm: "1.5rem", md: "2rem" },
                          }}
                        />
                      )}
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}

// Custom button styles
const buttonStyle = {
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 10,
  backgroundColor: "rgba(0,0,0,0.5)",
  color: "white",
  border: "none",
  borderRadius: "50%",
  width: { xs: "2rem", sm: "2.5rem", md: "3rem" },
  height: { xs: "2rem", sm: "2.5rem", md: "3rem" },
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};

export default CustomCarousel;
