import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../services/api";

export const RejectPendingRequest = createAsyncThunk(
  "RejectPendingRequest",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await api("post", "/reject-friend-request", formData);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const RejectPendingRequestSlice = createSlice({
  name: "RejectPendingRequest",
  initialState: {
    isLoading: false,
    user: null,
    status: "idle",
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(RejectPendingRequest.pending, (state) => {
        state.RejectPendingRequestisLoading = true;
        state.RejectPendingRequeststatus = "loading";
      })
      .addCase(RejectPendingRequest.fulfilled, (state, action) => {
        state.RejectPendingRequestisLoading = false;
        state.RejectPendingRequeststatus = "succeeded";
        state.RejectPendingRequestuser = action.payload.users;
      })
      .addCase(RejectPendingRequest.rejected, (state, action) => {
        state.RejectPendingRequestisLoading = false;
        state.RejectPendingRequeststatus = "failed";
        state.RejectPendingRequesterror = action.payload;
      });
  },
});

export default RejectPendingRequestSlice.reducer;
