import { Box } from "@mui/material";
import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

function TermsAndCondition() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
 
    <Box
      sx={{
        background: `linear-gradient(to right, #262829, #393B3D)`,
        width: "100%",
      }}
    >
      <Header color="black" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "6rem",
        }}
      >
        <Box
          sx={{
            color: "white",
            display: "flex",
            flexDirection: "column",
            width: "60%",
          }}
        >
		 <Helmet>
        <title>Terms & Conditions - Battlesync</title>
        <meta name="description" content="Terms and Conditons - Battlesync" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://battlesync.in/termsandConditions" />
      </Helmet>
          
          <h1>Terms & Conditions for Battle Sync</h1>
          <hr style={{ color: "yellow", width: "100%" }} />
          <h1>Terms and Conditions</h1>
          <p>
            <b>Effective Date:</b> November 17, 2024
          </p>
          <p>
            Welcome to <b>BattleSync</b> (the "Platform"). These Terms and
            Conditions (the "Terms") govern your access to and use of our
            website, services, and products located at https://battlesync.in
            (the "Website"). By accessing or using the Platform, you agree to be
            bound by these Terms.
          </p>
          <ol>
            <li>
              <h2>Introduction</h2>
            </li>
            <p>
              BattleSync is an online platform connecting users to create
              communities, organize esports tournaments, and manage purchases.
              By using our services, you acknowledge that you are at least 18
              years old or the legal age of majority in your jurisdiction. If
              you do not agree to these Terms, please discontinue using the
              Platform immediately.
            </p>
            <li>
              <h2>Account Creation and Content Upload</h2>
            </li>
            <ul>
              <li>
                <b>User Accounts:</b> Users may create accounts on the Platform.
                You are responsible for maintaining the confidentiality of your
                account information.
              </li>
              <li>
                <b>Content Upload:</b> Users can create and upload content,
                including, but not limited to, text, images, and videos. By
                uploading content, you warrant that you own or have the right to
                share the content and that it does not violate any third-party
                rights or applicable laws.
              </li>
            </ul>
            <li>
              <h2>Subscription Plans</h2>
            </li>
            <p>
              We offer subscription plans with additional features and benefits.
              By subscribing, you agree to the specific terms and fees
              applicable to your subscription. Subscriptions may be auto-renewed
              unless canceled per the cancellation terms provided during the
              subscription process.
            </p>
            <li>
              <h2>Intellectual Property</h2>
            </li>
            <ul>
              <li>
                The content, trademarks, and all intellectual property on the
                Platform, including but not limited to the "BattleSync" name,
                logo, and proprietary content, are the exclusive property of
                BattleSync. Users may not copy, reproduce, or use our
                intellectual property without prior written consent.
              </li>
              <li>
                Your uploaded content remains your property; however, by
                uploading it to the Platform, you grant us a non-exclusive,
                royalty-free, worldwide license to use, display, distribute, and
                promote your content on the Platform and associated services.
              </li>
            </ul>
            <li>
              <h2>Feedback and Suggestions</h2>
            </li>
            <p>
              If you provide feedback or suggestions to improve our Platform,
              you agree that BattleSync can use, share, and implement these
              suggestions without compensation or acknowledgment.
            </p>
            <li>
              <h2>Promotions, Contests, and Sweepstakes</h2>
            </li>
            <p>
              BattleSync may conduct promotions, contests, or sweepstakes. Each
              activity will have specific terms and conditions, which users must
              agree to in order to participate. These activities may be void
              where prohibited by law.
            </p>
            <li>
              <h2>User Responsibilities</h2>
            </li>
            <ul>
              <li>
                You agree not to use the Platform for any unlawful or harmful
                purposes, including but not limited to harassment, spamming, or
                infringing on others' rights.
              </li>
              <li>
                You are solely responsible for any content you upload and
                indemnify BattleSync from any claims arising from such content.
              </li>
            </ul>
            <li>
              <h2>Limitation of Liability</h2>
            </li>
            <p>
              BattleSync and its affiliates are not liable for any indirect,
              incidental, or consequential damages arising from your use of the
              Platform. Our liability is limited to the maximum extent permitted
              by applicable law.
            </p>
            <li>
              <h2>Termination</h2>
            </li>
            <p>
              We reserve the right to terminate or suspend your access to the
              Platform at any time, with or without notice, for violations of
              these Terms or other reasons at our sole discretion.
            </p>
            <li>
              <h2>Governing Law</h2>
            </li>
            <p>
              These Terms are governed by the laws of <b>India</b>, specifically
              the state of <b>Uttarakhand</b>, without regard to conflict of
              laws principles.
            </p>
            <li>
              <h2>Contact Us</h2>
            </li>
            <p>
              For any questions regarding these Terms and Conditions, you may
              contact us by email at <b>battlesync.in.</b>
            </p>
            <p>
              By accessing or using the BattleSync platform, you confirm your
              understanding and acceptance of these Terms and Conditions.
            </p>
          </ol>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default TermsAndCondition;
