import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";

function BlogDetails() {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const baseUrl = `${process.env.REACT_APP_CMS_PROTOCOL || ""}${
    process.env.REACT_APP_CMS_HOST || ""
  }${process.env.REACT_APP_CMS_PORT || ""}`;

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${baseUrl}/api/post/${slug}`);
        setBlog(response.data.blogs);
        setError(null);
      } catch (err) {
        console.error("Error fetching blog:", err);
        setError("Blog not found");
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [slug, baseUrl]);

  const formatTournamentDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleDateString("en-US", options).replace(",", "");
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          background: "#1c1b1a",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          background: "#1c1b1a",
          color: "#FFF",
          fontSize: "1.5rem",
        }}
      >
        {error}
      </Box>
    );
  }

  return (
    <Box sx={{ background: "#1c1b1a", width: "100%" }}>
      <Header color="black" />

      {blog.map((data, idx) => (
        <Box
          sx={{
            width: "60%",
            margin: "2rem auto",
            background: "#2a2927",
            borderRadius: "0.9rem",
            padding: "2rem",
            color: "#FFFFFF",
          }}
        >
          <Box
            component="img"
            src={data?.image}
            alt="data Thumbnail"
            sx={{ width: "100%", borderRadius: "0.5rem", marginBottom: "1rem" }}
          />
          <Box
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 400,
              fontSize: { xs: "1rem", sm: "1.5rem" },
              lineHeight: { xs: "1.5rem", sm: "2rem" },
              color: "#FAFAFA",
              marginBottom: "0.5rem",
            }}
          >
            {data.title}
          </Box>
          <Box
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 400,
              fontSize: { xs: "1rem", sm: "1.5rem" },
              lineHeight: { xs: "1.5rem", sm: "2rem" },
              color: "#FAFAFA",
              marginBottom: "0.5rem",
            }}
          >
            {data.authorName}
          </Box>
          <Box
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 400,
              fontSize: { xs: "0.9rem", sm: "1.2rem" },
              lineHeight: { xs: "1.2rem", sm: "1.8rem" },
              color: "#FAFAFA",
              marginBottom: "1rem",
            }}
          >
            Date: {formatTournamentDate(data.postDate)}
          </Box>
          <Box
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 400,
              fontSize: { xs: "1rem", sm: "1.5rem" },
              lineHeight: { xs: "1.5rem", sm: "2rem" },
              color: "#FAFAFA",
              marginBottom: "0.5rem",
            }}
          >
            Email: {data.authorEmail}
          </Box>
          <Box
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 500,
              fontSize: { xs: "0.9rem", sm: "1.1rem" },
              lineHeight: { xs: "1.3rem", sm: "1.8rem" },
              color: "#F6F6F6",
              textOverflow: "ellipsis",
              overflow: "hidden",
              wordWrap: "break-word",
            }}
            dangerouslySetInnerHTML={{
              __html: data.content
                .replace(
                  /<table/g,
                  '<table style="border: 2px solid white; border-collapse: collapse; width: 100%;"'
                )
                .replace(
                  /<td/g,
                  '<td style="border: 2px solid white; padding: 5px; text-align: left;"'
                )
                .replace(
                  /<th/g,
                  '<th style="border: 2px solid white; padding: 5px; text-align: left;"'
                ),
            }}
          />
          <Box sx={{ gap: "1rem", margin: "5rem" }}>
            <Box
              sx={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 700,
                fontSize: { xs: "2rem", sm: "2.4rem", md: "2.4rem" },
                lineHeight: { xs: "2.8rem", sm: "3.6rem", md: "3.6rem" },
                color: "#FAFAFA",
              }}
            >
              {" "}
              Tags:
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "0.5rem",
              }}
            >
              {data.tags &&
                data.tags.map((tag, index) => (
                  <Box
                    key={index}
                    sx={{
                      color: "white",
                      background: "#535C68",
                      borderRadius: "0.7rem",
                      padding: "0.5rem 1rem",
                      alignItems: "center",
                      fontFamily: "PostNoBillsJaffna",
                      fontWeight: 800,
                      fontSize: {
                        xs: "1rem",
                        sm: "1.4rem",
                        md: "1.4rem",
                      },
                      lineHeight: {
                        xs: "1rem",
                        sm: "1.4rem",
                        md: "1.4rem",
                      },
                    }}
                  >
                    {tag}
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
      ))}

      <Footer />
    </Box>
  );
}

export default BlogDetails;
