import { Box } from "@mui/material";
import React from "react";
import AffiliateCrousel from "../shared/CustomCarousel/AffliliateCrousel";

function AffilateProducts({ data }) {
  return (
    <Box
      sx={{
        backgroundColor: "#171717",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: { xs: "4rem", sm: "6rem", md: "8rem" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            color: "#F3F7EC",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: { xs: "2rem", sm: "3.2rem", md: "4.8rem" },
            lineHeight: { xs: "2.2rem", sm: "4.8rem", md: "7.2rem" },
            textAlign: "left",
          }}
        >
          Our Affiliated Products
        </Box>
      </Box>
      <Box sx={{ paddingTop: { xs: "2rem", sm: "4rem", md: "6rem" } }}>
        <AffiliateCrousel items={data} />
      </Box>
    </Box>
  );
}

export default AffilateProducts;
