import React, { useState } from "react";
import { Box, Button, Snackbar, Alert } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import EditIcon from "@mui/icons-material/Edit";

const ImageUploader = ({ onImageUpload, imageSrc, maxSize = 200 * 1024 }) => {
  // Accept maxSize as a prop with a default of 200KB
  const [preview, setPreview] = useState(imageSrc);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const validImageTypes = ["image/jpeg", "image/png", "image/webp"];
      if (!validImageTypes.includes(file.type)) {
        setError("Please upload a valid image file (JPEG, PNG, WebP).");
        setPreview(null);
        setOpenSnackbar(true);
        return;
      }

      if (file.size > maxSize) {
        setError(
          `Image size exceeds ${
            maxSize / 1024
          }KB! Please upload a smaller image.`
        );
        setPreview(null);
        setOpenSnackbar(true);
        return;
      }

      setPreview(URL.createObjectURL(file));
      onImageUpload(file);
      setError(null);
    }
  };

  const triggerFileInput = () => {
    document.getElementById("select-image").click();
  };

  const handleCloseSnackbar = () => setOpenSnackbar(false);

  return (
    <Box
      textAlign="center"
      position="relative"
      width="20rem"
      height="20rem"
      mx="auto"
      sx={{ margin: "0.5rem auto" }}
    >
      <input
        accept="image/jpeg,image/png,image/webp"
        type="file"
        id="select-image"
        style={{ display: "none" }}
        onChange={handleImageChange}
      />

      {preview ? (
        <Box mt={2} textAlign="center" position="relative">
          <img
            src={preview}
            alt="Selected"
            style={{
              width: "20rem",
              height: "20rem",
              objectFit: "cover",
              border: "1px dashed #ccc",
              borderRadius: "50%",
            }}
          />
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
          border="1px dashed #ccc"
          borderRadius="50%"
        >
          <ImageIcon style={{ fontSize: "5rem", color: "#ccc" }} />
        </Box>
      )}

      <Button
        onClick={triggerFileInput}
        sx={{
          borderRadius: "20rem",
          position: "absolute",
          right: "10%",
          bottom: 0,
          color: "white",
          background: "#686DE0",
        }}
      >
        <EditIcon />
      </Button>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ImageUploader;
