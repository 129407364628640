import { Box, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import Header from "./component/Header";
import ClearIcon from "@mui/icons-material/Clear";
import { Link } from "react-router-dom";

function Layout({ children }) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const maxOpacity = 0.9;
  const headerOpacity = Math.min(scrollPosition / 200, maxOpacity);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 500);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 9999,
          backgroundColor: `rgba(0, 0, 0, ${headerOpacity})`,
          transition: "background-color 0.3s",
        }}
      >
        <Header />
        {isVisible && (
          <Box
            sx={{
              background: "#686DE0",
              height: { xs: "4rem", sm: "6rem", md: "8rem" },
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              position: "relative",
              transition: "transform 0.5s ease, opacity 0.5s ease",
              transform: isClosing ? "translateY(-100%)" : "translateY(0)",
              opacity: isClosing ? 0 : 1,
            }}
          >
            <Box sx={{ color: "white", width: "100%" }}>
              <IconButton
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  color: "white",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <ClearIcon />
              </IconButton>
              <Box
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 600,
                  fontSize: { xs: "0.9rem", sm: "1.5rem", md: "2rem" },
                  lineHeight: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
                  textAlign: "center",
                  color: "white",
                }}
              >
                Beta Version Announcement: "This is our beta version!" Signup
                Incentive:{" "}
                <Link
                  to="/signUp"
                  style={{
                    textDecoration: "none",
                    color: "#FFD700",
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      "&:hover": {
                        color: "#FFA500",
                      },
                    }}
                  >
                    "Sign up now to receive"
                  </Box>
                </Link>
              </Box>

              <Box sx={{ width: "100%", mt: { xs: "0.3rem", sm: "0.8rem" } }}>
                <ul
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: { xs: "0.9rem", sm: "4rem", md: "6rem" },
                      fontFamily: "Poppins, sans-serif",
                      fontSize: { xs: "0.9rem", sm: "1.6rem" },
                      color: "white",
                    }}
                  >
                    <li>200 Battle Coins</li>
                    <li>A free level up to Level 5</li>
                  </Box>
                </ul>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box>{children}</Box>
    </React.Fragment>
  );
}

export default Layout;
