import { Box, Divider } from "@mui/material";
import React from "react";
import Logo from "../Assests/Logo/Logo.svg";
import Twitter from "../Assests/Logo/Twitter.svg";
import Facebook from "../Assests/Logo/Facebook.svg";
import Instagram from "../Assests/Logo/Instagram.svg";
import Youtube from "../Assests/Logo/youtube.svg";
import Quora from "../Assests/Logo/Quora.svg";
import Reddit from "../Assests/Logo/Reddit.svg";
import ToTheTop from "../Assests/Logo/ToTheTop.svg";
import "../style/fontStyle.css";
import { Link } from "react-router-dom";

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Box
      sx={{
        backgroundColor: "#171717",
        padding: {
          xs: "2rem",
          sm: "2rem 5rem",
          md: "2rem 8rem",
          lg: "2rem 11rem",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "1.6rem",
          lineHeight: "1",
          gap: { xs: "", md: "10rem" },
        }}
      >
        <Box>
          <img
            src={Logo}
            alt="Logo"
            style={{ height: "4rem", width: "8rem" }}
          />
        </Box>

        {/* Navigation Links */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: { xs: "2rem", sm: "3rem", md: "3rem" },
            color: "white",
            flexWrap: "wrap",
          }}
        >
          {[
            { path: "/post", label: "Blogs" },
            { path: "/login", label: "Live Streams" },
            { path: "/login", label: "Tournaments" },
            { path: "/privacy-policy", label: "Privacy & Policy" },
            { path: "/contact-us", label: "Contact Us" },
            { path: "/termsandConditions", label: "Terms & Conditions" },
            { path: "/about-us", label: "About Us" },
          ].map(({ path, label }, index) => (
            <Box
              sx={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 400,
                fontSize: { xs: "1rem", md: "2.2rem" },
                lineHeight: { xs: "1.4rem", md: "3.3rem" },
                cursor: "pointer",
              }}
              key={index}
            >
              <Link
                to={path}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {label}
              </Link>
            </Box>
          ))}
        </Box>

        {/* Social Media Links */}
        <Box
          sx={{
            display: "flex",
            gap: { xs: "1rem", md: "2rem" },
            justifyContent: "center",
          }}
        >
          {[
            {
              href: "https://x.com/battle_sync_gg",
              icon: Twitter,
              alt: "Twitter",
            },
            {
              href: "https://www.facebook.com/profile.php?id=61568669413414",
              icon: Facebook,
              alt: "Facebook",
            },
            {
              href: "https://www.instagram.com/battlesync.gg",
              icon: Instagram,
              alt: "Instagram",
            },
            {
              href: "https://www.reddit.com/r/BattleSync/",
              icon: Reddit,
              alt: "Reddit",
            },
            {
              href: "https://www.youtube.com/@BattleSync-gg",
              icon: Youtube,
              alt: "Youtube",
            },
            { href: "https://battlesync.quora.com", icon: Quora, alt: "Quora" },
          ].map(({ href, icon, alt }, index) => (
            <a
              href={href}
              target="blank"
              key={index}
              style={{ display: "flex" }}
            >
              {typeof icon === "string" ? (
                <img
                  src={icon}
                  alt={alt}
                  style={{ height: "1.6vw", width: "1.6vw" }}
                />
              ) : (
                icon
              )}
            </a>
          ))}
        </Box>
      </Box>
      <Divider sx={{ borderColor: "white" }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "center", md: "stretch" },
          gap: { xs: "1rem", md: "0" },
          marginTop: { xs: "0.5rem", md: "1rem" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: { xs: "center", md: "stretch" },
          }}
        >
          <Box
            sx={{
              color: "white",
              fontFamily: "Poppins, sans-serif",
              fontWeight: 400,
              fontSize: { xs: "1rem", md: "1.4rem" },
              lineHeight: "4.5rem",
              textAlign: "left",
            }}
          >
            COOKIE PREFERENCES
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              borderColor: "white",
              display: { xs: "none", md: "block" },
              margin: "1rem 0rem",
            }}
          />
          <Box
            sx={{
              color: "white",
              fontFamily: "Poppins, sans-serif",
              fontWeight: 400,
              fontSize: { xs: "1rem", md: "1.4rem" },
              lineHeight: "4.5rem",
              textAlign: "left",
            }}
          >
            ©️ BattleSync All Rights Reserved.
          </Box>
        </Box>

        {/* Scroll to Top */}
        <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <Box
            sx={{
              color: "white",
              fontFamily: "Squada One, sans-serif",
              fontWeight: 400,
              fontSize: { xs: "1.5rem", md: "2.0rem" },
              lineHeight: "4.5rem",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={scrollToTop}
          >
            TO THE TOP
          </Box>
          <Box onClick={scrollToTop}>
            <img
              src={ToTheTop}
              alt="To The Top"
              style={{ height: "2rem", width: "2rem", cursor: "pointer" }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
