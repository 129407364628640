import { Box } from "@mui/material";
import React from "react";
import AboutUsCard from "../shared/Card/AboutUsCard";

function AboutUs({ show }) {
  const content =
    "BattleSync is an esports tournament organizing platform that bridges the gap between players, teams, and event organizers. Whether you're a casual gamer looking for friendly competition or a pro team seeking high-stakes tournaments, BattleSync provides a streamlined, user-friendly experience for organizing and participating in esports events across various games and platforms. The platform allows users to create and manage tournaments, sign up for events, and connect with players around the globe. With support for multiple game titles, customizable tournament formats, and automated bracket generation, BattleSync simplifies the entire tournament process.";

  const content2 = [
    { name: "Satyam Singh", des: "Founder, The Rich Bud, Technophile" },
    {
      name: "Mukul Rajput",
      des: "The Creative Junkee, Design Jedi, UI/UX Designer",
    },
    {
      name: "Arjun Pradhan",
      des: "Browser Whisperer, Client-side Ninja, “It Works on my machine”",
    },
    {
      name: "Abhishek Biswas",
      des: "Chief technician, The Digital Overlord, Tech Sage",
    },
  ];

  return (
    <Box
      sx={{
        height: "100%",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: 6,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "grey",
          borderRadius: "10px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          fontFamily: "Poppins, sans-serif !important",
          fontWeight: 700,
          fontSize: { xs: "3.6rem", sm: "3rem", md: "4.8rem" },
          lineHeight: { xs: "5.4rem", sm: "6rem", md: "7.2rem" },
          color: "white",
        }}
      >
        About Us
      </Box>

      <Box sx={{ width: "96%", margin: "0 auto" }}>
        <AboutUsCard title="The Projects" content={content} />
        <br />
        <AboutUsCard
          title="The People"
          content={content2.map((person) => (
            <Box
              key={person.name}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
                padding: "1rem 0",
              }}
            >
              <Box
                sx={{
                  fontFamily: "Poppins, sans-serif !important",
                  fontWeight: 700,
                  fontSize: { xs: "1.4rem", sm: "2rem", md: "2.4rem" },
                  lineHeight: { xs: "2.1rem", sm: "3rem", md: "3.6rem" },
                  color: "#686DE0",
                  textAlign: "left",
                  flex: 1,
                  paddingRight: { sm: "1rem" },
                }}
              >
                {person.name}
              </Box>

              <Box
                sx={{
                  fontFamily: "Poppins, sans-serif !important",
                  fontWeight: 400,
                  fontSize: { xs: "1.2rem", sm: "1.6rem", md: "2rem" },
                  lineHeight: { xs: "1.6rem", sm: "2.4rem", md: "3rem" },
                  color: "#F9F9F9",
                  textAlign: "left",
                  flex: 2,
                }}
              >
                {person.des}
              </Box>
            </Box>
          ))}
        />
      </Box>
      <br />
      {show ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              fontFamily: "Poppins, sans-serif !important",
              fontWeight: 700,
              fontSize: { xs: "1.6rem", sm: "2.4rem", md: "2.4rem" },
              lineHeight: { xs: "1.4rem", sm: "3.6rem", md: "3.6rem" },
              color: "white",
              padding: "0.6rem 1.3rem",
            }}
          >
            Contact Us :
          </Box>
          <Box
            sx={{
              fontFamily: "Poppins, sans-serif !important",
              fontWeight: 700,
              fontSize: { xs: "1.6rem", sm: "2.4rem", md: "2.4rem" },
              lineHeight: { xs: "1.4rem", sm: "3.6rem", md: "3.6rem" },
              color: "white",
            }}
          >
            battlesync.gg@gmail.com
          </Box>
        </Box>
      ) : (
        <>""</>
      )}
    </Box>
  );
}

export default AboutUs;
