import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../services/api";

export const searchFriend = createAsyncThunk(
  "search/fetchUser",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await api("post", "/searchUser", formData);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const searchFriendSlice = createSlice({
  name: "search",
  initialState: {
    searchFriendisLoading: false,
    searchFrienduser: null,
    searchFriendstatus: "idle",
    searchFrienderror: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchFriend.pending, (state) => {
        state.searchFriendisLoading = true;
        state.searchFriendstatus = "loading";
      })
      .addCase(searchFriend.fulfilled, (state, action) => {
        state.searchFriendisLoading = false;
        state.searchFriendstatus = "succeeded";
        state.searchFrienduser = action.payload;
      })
      .addCase(searchFriend.rejected, (state, action) => {
        state.searchFriendisLoading = false;
        state.searchFriendstatus = "failed";
        state.searchFrienderror = action.payload;
      });
  },
});

export default searchFriendSlice.reducer;
