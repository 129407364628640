import React, { useEffect, useState } from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import BlogDetails from "./BlogDetails";

function BlogPost() {
  const baseUrl = `${process.env.REACT_APP_CMS_PROTOCOL}${process.env.REACT_APP_CMS_HOST}${process.env.REACT_APP_CMS_PORT}`;
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchPosts = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/post`);
      return response.data;
    } catch (err) {
      console.error("Error fetching posts:", err.message);
      throw err;
    }
  };

  useEffect(() => {
    const loadPosts = async () => {
      try {
        const posts = await fetchPosts();
        setBlogData(posts || []);
        setLoading(false);
      } catch (err) {
        setError("Failed to load blogs. Please try again later.");
        setLoading(false);
      }
    };

    loadPosts();
  }, [baseUrl]);

  const formatTournamentDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleDateString("en-US", options).replace(",", "");
  };

  const handleNavigateToBlog = (data) => {
    const slug = data.title.toLowerCase().replace(/[^a-z0-9]+/g, "-");
    navigate(`/post/${slug}`);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Header color="black" />

      <Routes>
        <Route path="/post/:slug" element={<BlogDetails />} />
        <Route
          path="/"
          element={
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "#1c1b1a",
                overflow: "auto",
                height: "100vh",
              }}
            >
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    color: "#FFFFFF",
                  }}
                >
                  <CircularProgress color="inherit" />
                  <Box
                    sx={{
                      marginLeft: "1rem",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "1.2rem",
                    }}
                  >
                    Loading...
                  </Box>
                </Box>
              ) : error ? (
                // Show error message if there's an error
                <Box
                  sx={{
                    color: "#FFFFFF",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "1.5rem",
                    textAlign: "center",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {error}
                </Box>
              ) : blogData.length > 0 ? (
                blogData.map((data, idx) => (
                  <Box
                    key={idx}
                    sx={{
                      width: "60%",
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      margin: "2rem 0",
                      border: "1px solid #454545",
                      borderRadius: "0.9rem",
                      overflow: "hidden",
                      background: "#2a2927",
                      cursor: "pointer",
                    }}
                    onClick={() => handleNavigateToBlog(data)}
                  >
                    <Box
                      component="img"
                      src={data?.image}
                      alt="Blog Thumbnail"
                      sx={{
                        width: { xs: "100%", sm: "40%" },
                        height: "auto",
                        objectFit: "cover",
                      }}
                    />
                    <Box
                      sx={{
                        padding: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1,
                        width: { xs: "96%", sm: "58%" },
                      }}
                    >
                      <Box
                        sx={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 400,
                          fontSize: { xs: "1rem", sm: "1.5rem" },
                          color: "#FAFAFA",
                          marginBottom: "0.5rem",
                        }}
                      >
                        Author: {data.authorName}
                      </Box>
                      <Box
                        sx={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 400,
                          fontSize: { xs: "0.9rem", sm: "1.2rem" },
                          color: "#FAFAFA",
                          marginBottom: "1rem",
                        }}
                      >
                        Date: {formatTournamentDate(data.postDate)}
                      </Box>
                      <Box
                        sx={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 400,
                          fontSize: { xs: "1rem", sm: "1.5rem" },
                          color: "#FAFAFA",
                          marginBottom: "0.5rem",
                        }}
                      >
                        Email: {data.authorEmail}
                      </Box>
                      <Box
                        sx={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 700,
                          fontSize: { xs: "0.8rem", sm: "1.8rem", md: "2rem" },
                          color: "#FFFFFF",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          overflow: "hidden",
                        }}
                      >
                        Heading: {data.title}
                      </Box>
                      <Box
                        sx={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 500,
                          fontSize: { xs: "0.8rem", sm: "1.3rem", md: "1rem" },
                          color: "#F6F6F6",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 6,
                          WebkitBoxOrient: "vertical",
                          whiteSpace: "normal",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: data.content
                            .replace(
                              /<table/g,
                              '<table style="border: 2px solid white; border-collapse: collapse; width: 100%;"'
                            )
                            .replace(
                              /<td/g,
                              '<td style="border: 2px solid white; padding: 5px; text-align: left;"'
                            )
                            .replace(
                              /<th/g,
                              '<th style="border: 2px solid white; padding: 5px; text-align: left;"'
                            ),
                        }}
                      />
                    </Box>
                  </Box>
                ))
              ) : (
                <Box
                  sx={{
                    color: "#FFFFFF",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "1.5rem",
                    textAlign: "center",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  No blogs available.
                </Box>
              )}
            </Box>
          }
        />
      </Routes>
      <Footer />
    </Box>
  );
}

export default BlogPost;
