import React, { createContext, useContext, useState } from "react";
import { ProSidebarProvider, useProSidebar } from "react-pro-sidebar";
import MyProSidebar from "./MyProSidebar";

const SidebarContext = createContext({});

const InnerSidebarProvider = ({ children }) => {
  const {
    collapsed: isFirstSidebarCollapsed,
    collapseSidebar: toggleFirstSidebar,
  } = useProSidebar();
  const [isSecondSidebarCollapsed, setIsSecondSidebarCollapsed] =
    useState(true);

  const toggleFirstSidebarAndCollapseSecond = () => {
    toggleFirstSidebar();
    setIsSecondSidebarCollapsed(true);
  };

  return (
    <SidebarContext.Provider
      value={{
        isFirstSidebarCollapsed,
        toggleFirstSidebar: toggleFirstSidebarAndCollapseSecond,
        isSecondSidebarCollapsed,
        setIsSecondSidebarCollapsed,
      }}
    >
      <div style={{ display: "flex" }}>
        <MyProSidebar
          collapsed={isFirstSidebarCollapsed}
          toggleSidebars={toggleFirstSidebarAndCollapseSecond}
        />
        {children}
      </div>
    </SidebarContext.Provider>
  );
};

export const MyProSidebarProvider = ({ children }) => (
  <ProSidebarProvider>
    <InnerSidebarProvider>{children}</InnerSidebarProvider>
  </ProSidebarProvider>
);

export const useSidebarContext = () => useContext(SidebarContext);
