import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../services/api";

export const requestFriend = createAsyncThunk(
  "request",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await api("post", "/send-request", formData);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const requestFriendSlice = createSlice({
  name: "request",
  initialState: {
    isLoading: false,
    user: null,
    status: "idle",
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestFriend.pending, (state) => {
        state.requestFriendisLoading = true;
        state.requestFriendstatus = "loading";
      })
      .addCase(requestFriend.fulfilled, (state, action) => {
        state.requestFriendisLoading = false;
        state.requestFriendstatus = "succeeded";
        state.requestFrienduser = action.payload;
      })
      .addCase(requestFriend.rejected, (state, action) => {
        state.requestFriendisLoading = false;
        state.requestFriendstatus = "failed";
        state.requestFrienderror = action.payload;
      });
  },
});

export default requestFriendSlice.reducer;
