import { Alert, Box, Divider, Snackbar } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import History from "./History";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CustomInput, {
  CustomInputWithoutIcon,
} from "../shared/CustomInput/CustomInput";
import ImageUploader from "../shared/ImageUploader/ImageUploader ";
import Button from "../shared/Button/Button";
import { updateProfile } from "../redux/features/dashboard/updateProfile";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

function Profile() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.dashboard);
  const userName = user?.first_name + " " + user?.last_name || [];
  let globalUser = user?.username;

  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    username: globalUser,
    user_profile_picture: null,
  });

  const { profileImageuser } = useSelector((state) => state.profileImage);
  const [imageSrc, setImageSrc] = useState(
    profileImageuser ? `data:image/png;base64,${profileImageuser?.image}` : null
  );

  const [showEditProfile, setShowEditProfile] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [dataFromChild, setDataFromChild] = useState(null);

  const handleDataFromChild = (data) => {
    setDataFromChild(data);
  };

  useEffect(() => {
    // Update imageSrc when profileImageuser changes
    if (profileImageuser) {
      setImageSrc(`data:image/png;base64,${profileImageuser?.image}`);
    }
  }, [profileImageuser]);

  const handleImageUpload = (imageFile) => {
    setFormValues({
      ...formValues,
      username: globalUser,
      user_profile_picture: imageFile,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    for (const key in formValues) {
      formData.append(key, formValues[key]);
    }

    try {
      const actionResult = await dispatch(updateProfile(formData));
      if (updateProfile.fulfilled.match(actionResult)) {
        setSnackbarMessage("Profile updated successfully!");
        setSnackbarSeverity("success");
      } else {
        setSnackbarMessage("Failed to update profile.");
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setSnackbarMessage("Error during profile update.");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
      // Force refresh after update
      window.location.reload();
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        height: "100%",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: 6,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "grey",
          borderRadius: "10px",
        },
      }}
    >
      {!showEditProfile ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              margin: "3.5rem 0rem ",
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0rem 2rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Box
                  component="img"
                  src={imageSrc}
                  alt="User img"
                  sx={{
                    width: { xs: "14rem", sm: "14rem", md: "18rem" },
                    height: { xs: "14rem", sm: "14rem", md: "18rem" },
                    objectFit: "cover",
                    display: "block",
                    borderRadius: "50%",
                    border: imageSrc ? "none" : "1px dashed #ccc",
                  }}
                />
              </Box>
              <Box
                sx={{
                  textAlign: "center",
                  width: "100%",
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 600,
                  fontSize: { xs: "1.6rem", sm: "1.6rem", md: "2.2rem" },
                  lineHeight: { xs: "1.9rem", sm: "1.9rem", md: "2.6rem" },
                  color: "white",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {userName}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  background: "#686DE0",
                  borderRadius: "0.6rem",
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 300,
                  fontSize: "1.6rem",
                  lineHeight: "2rem",
                  color: "white",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "fit-content",
                  padding: "0.5rem 1rem",
                  margin: "0.5rem",
                  cursor: "pointer",
                }}
                onClick={() => setShowEditProfile(true)}
              >
                Edit Profile &nbsp; <ModeEditIcon />
              </Box>
            </Box>

            <Box
              sx={{
                flex: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box>
                {" "}
                <Box
                  sx={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 400,
                    fontSize: { xs: "1rem", sm: "1.6rem", md: "2rem" },
                    lineHeight: { xs: "1.5rem", sm: "2.4rem", md: "3rem" },
                    color: "#B1B1B1",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Recent Activity
                </Box>
                <Box
                  sx={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 700,
                    fontSize: { xs: "1.2rem", sm: "1.6rem", md: "2.4rem" },
                    lineHeight: { xs: "1.8rem", sm: "2.4rem", md: "3.6rem" },
                    color: "white",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Recently played {dataFromChild} Number of Matches
                </Box>
              </Box>
            </Box>
          </Box>
          <Divider sx={{ width: "100%", borderBottom: "1px solid #3F3F3F" }} />
          <Box sx={{ padding: "2.5rem 2.5rem 0rem 2.5rem" }}>
            <Box
              sx={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 700,
                fontSize: { xs: "1.6rem", sm: "2.4rem", md: "3.6rem" },
                lineHeight: { xs: "2.4rem", sm: "3.6rem", md: "5.4rem" },
                color: "white",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Match History
            </Box>
            <Box>
              <History onDataFetch={handleDataFromChild} />
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              fontFamily: "Poppins, sans-serif !important",
              fontWeight: 700,
              fontSize: { xs: "1rem", sm: "1rem", md: "1rem" },
              lineHeight: { xs: "1.5rem", sm: "1.5rem", md: "1.5rem" },
              color: "white",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              background: "#3D3D3D",
              width: "min-content",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              padding: "0.6rem",
            }}
            onClick={() => setShowEditProfile(false)}
          >
            <KeyboardBackspaceIcon sx={{ margin: "0rem 0.2rem" }} /> Back
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "8%",
            }}
          >
            <Box
              sx={{
                border: "1px solid #3F3F3F",
                borderRadius: "0.8rem",
                width: "fit-content",
                padding: "2rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <ImageUploader
                  onImageUpload={handleImageUpload}
                  imageSrc={imageSrc}
                  maxSize={55 * 1024}
                />
                <Box
                  sx={{
                    display: "flex",
                    gap: "2rem",
                  }}
                >
                  <CustomInputWithoutIcon
                    name="first_name"
                    value={formValues.first_name}
                    onChange={handleChange}
                    placeholder={user?.first_name}
                    bordercolor="0.2rem solid #949494"
                    color="white"
                    required
                  />
                  <CustomInputWithoutIcon
                    name="last_name"
                    value={formValues.last_name}
                    onChange={handleChange}
                    placeholder={user?.last_name}
                    required
                    bordercolor="0.2rem solid #949494"
                    color="white"
                  />
                </Box>
                <CustomInput
                  name="username"
                  value={formValues.username}
                  onChange={handleChange}
                  placeholder={user?.username}
                  required
                  bordercolor="0.2rem solid #949494"
                  color="white"
                  disabled="disabled"
                />
                <Button text="Update Profile" type="submit" width="60%" />
              </form>
            </Box>
          </Box>
        </>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: "100%", fontSize: "1.4rem" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Profile;
