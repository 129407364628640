import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../style/fontStyle.css";
import VideoPlayer from "../shared/CustomVideo/VideoPlayer";
import axios from "axios";

function WhatHappening() {
  const [currentVideo, setCurrentVideo] = useState(null);
  const [youTubeData, setYouTubeData] = useState([]);
  const YOUTUBE_API_KEY = `${process.env.REACT_APP_YOUTUBE_API_KEY}`;
  const basUrl = `${process.env.REACT_APP_CMS_PROTOCOL}${process.env.REACT_APP_CMS_HOST}${process.env.REACT_APP_CMS_PORT}`;

  useEffect(() => {
    const fetchYouTubeLinks = async () => {
      try {
        const response = await axios.get(`${basUrl}/api/youtube-links`);
        const links = response.data;

        // Fetch video details for each link
        const videoDetails = await Promise.all(
          links.slice(0, 3).map(async (item) => {
            const videoId = extractVideoId(item.link);
            if (videoId) {
              const details = await fetchVideoDetails(videoId);
              return { ...item, ...details };
            }
            return item;
          })
        );

        setYouTubeData(videoDetails);

        // Set the first video as the current video if videoDetails is not empty
        if (videoDetails.length > 0) {
          const firstVideo = videoDetails[0];
          setCurrentVideo({
            url: firstVideo.link,
            poster: getThumbnailUrl(firstVideo.link),
            title: firstVideo.title,
            description: firstVideo.description,
            date: firstVideo.date,
          });
        }
      } catch (error) {
        console.error("Failed to fetch YouTube data:", error);
      }
    };

    fetchYouTubeLinks();
  }, []);

  const extractVideoId = (url) => {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|v\/|embed\/|watch\?v=|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const getThumbnailUrl = (url) => {
    const videoId = extractVideoId(url);
    return videoId
      ? `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
      : "";
  };

  const fetchVideoDetails = async (videoId) => {
    try {
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${YOUTUBE_API_KEY}&part=snippet`
      );
      const videoData = response.data.items[0].snippet;
      return {
        link: `https://www.youtube.com/watch?v=${videoId}`,
        title: videoData.title,
        description: videoData.description,
        date: videoData.publishedAt,
      };
    } catch (error) {
      console.error(`Failed to fetch details for video ID ${videoId}:`, error);
      return null;
    }
  };

  const handleTileClick = (url) => {
    const videoId = extractVideoId(url);
    const thumbnailUrl = getThumbnailUrl(url);
    setCurrentVideo({
      url: videoId ? `https://www.youtube.com/watch?v=${videoId}` : "",
      poster: thumbnailUrl,
      playing: true,
      title: currentVideo?.title || "", // Retain previous title if switching
      description: currentVideo?.description || "", // Retain previous description if switching
      date: currentVideo?.date || "", // Retain previous date if switching
    });
  };

  function formatTournamentDate(dateString) {
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleDateString("en-US", options).replace(",", "");
  }

  return (
    <Box sx={{ padding: { xs: "4rem", sm: "6rem", md: "8rem" } }}>
      <Box
        sx={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: 700,
          fontSize: { xs: "2rem", sm: "3.2rem", md: "4.8rem" },
          lineHeight: { xs: "2.2rem", sm: "4.8rem", md: "7.2rem" },
          color: "black",
        }}
      >
        What’s Happening
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "2rem",
          flexDirection: { xs: "column", sm: "row" },
          mt: { xs: "4rem", sm: "1rem" },
        }}
      >
        <Box sx={{ width: { xs: "100%", sm: "60%" } }}>
          {currentVideo && <VideoPlayer url={currentVideo.url} />}
        </Box>
        <Box sx={{ width: { xs: "100%", sm: "40%" } }}>
          {currentVideo && (
            <>
              <Box
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 700,
                  fontSize: { xs: "1rem", sm: "1.4rem", md: "1.6rem" },
                  lineHeight: { xs: "1rem", sm: "1.4rem", md: "2.4rem" },
                  color: "#525252",
                }}
              >
                Date: {formatTournamentDate(currentVideo.date)}{" "}
              </Box>
              <Box
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 700,
                  fontSize: { xs: "1rem", sm: "1.4rem", md: "4.2rem" },
                  lineHeight: { xs: "1rem", sm: "1.4rem", md: "7.2rem" },
                  color: "#202020",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  display: "block",
                  maxWidth: "100%",
                }}
              >
                {currentVideo.title}
              </Box>

              <Box
                sx={{
                  marginBottom: "2rem",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 400,
                  fontSize: { xs: "1rem", sm: "1.2rem", md: "1.6rem" },
                  lineHeight: { xs: "1.5  rem", sm: "1.8rem", md: "2.4rem" },
                  color: "#202020",
                }}
              >
                DESCRIPTION
              </Box>
              <Box
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: { xs: "4", sm: "10", md: "10" },
                  flexDirection: "column",
                  height: "auto",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 400,
                  fontSize: { xs: "1rem", sm: "1.2rem", md: "1.6rem" },
                  lineHeight: { xs: "1.5  rem", sm: "1.8rem", md: "2.4rem" },
                  color: "#202020",
                }}
              >
                {currentVideo.description || "No Description..."}{" "}
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          mt: {
            xs: "0.5rem",
            sm: "4rem",
          },
          display: "flex",
          gap: { xs: "1rem", sm: "2.5rem", md: "5rem" },
        }}
      >
        {youTubeData.map((item, index) => {
          const thumbnailUrl = getThumbnailUrl(item.link);

          return (
            <Box
              key={index}
              sx={{
                width: "100%",
                height: { xs: "9rem", sm: "10rem", md: "18rem" },
                backgroundImage: `url(${thumbnailUrl})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "1rem",
                cursor: "pointer",
              }}
              onClick={() => {
                handleTileClick(item.link);
                setCurrentVideo({
                  url: item.link,
                  poster: thumbnailUrl,
                  playing: true,
                  title: item.title,
                  description: item.description,
                  date: item.date,
                });
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
}

export default WhatHappening;
