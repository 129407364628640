import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../services/api";

export const getCommunity = createAsyncThunk(
  "getCommunity",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api("get", "/api/get/community");
      return response;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "An error occurred while posting community."
      );
    }
  }
);

const getCommunitySlice = createSlice({
  name: "getCommunity",
  initialState: {
    getCommunityisLoading: false,
    getCommunityuser: null,
    getCommunitystatus: "idle",
    getCommunityerror: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCommunity.pending, (state) => {
        state.getCommunityisLoading = true;
        state.getCommunitystatus = "loading";
      })
      .addCase(getCommunity.fulfilled, (state, action) => {
        state.getCommunityisLoading = false;
        state.getCommunitystatus = "succeeded";
        state.getCommunityuser = action.payload;
      })
      .addCase(getCommunity.rejected, (state, action) => {
        state.getCommunityisLoading = false;
        state.getCommunitystatus = "failed";
        state.getCommunityerror = action.payload;
      });
  },
});

export default getCommunitySlice.reducer;
