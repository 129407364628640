import React from "react";
import { Box } from "@mui/material";

function Button({ text, type, width, marginTop }) {
  return (
    <Box
      component="button"
      type={type}
      sx={{
        background: "#3C5B80",
        color: "white",
        padding: "0.5rem 2rem",
        borderRadius: "2.9rem",
        border: "0.1rem solid white",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 500,
        fontSize: { xs: "1.4rem", sm: "2rem", md: "2.4rem" },
        lineHeight: { xs: "2.1rem", sm: "3rem", md: "3.6rem" },
        textAlign: "center",
        cursor: "pointer",
        width: width ? width : "100%",
        display: "flex",
        justifyContent: "center",
        margin: marginTop ? marginTop : "0 auto",
      }}
    >
      {text}
    </Box>
  );
}

export default Button;
