import { Box, Divider, TextField, Button, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ChatIcon from "@mui/icons-material/Chat";
import SendIcon from "@mui/icons-material/Send";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import axios from "axios";
import { getCommunity } from "../redux/features/friends/getCommunity";
import { profileImage } from "../redux/features/dashboard/profile_imageSlice";

function Feed() {
  const dispatch = useDispatch();
  const [likes, setLikes] = useState({});
  const [likedPosts, setLikedPosts] = useState(new Set());
  const [comments, setComments] = useState({});
  const [commentText, setCommentText] = useState({});
  const [profilePictures, setProfilePictures] = useState({});
  const [feedPictures, setFeedPictures] = useState({});
  const [expandedComments, setExpandedComments] = useState({}); // Track expanded comments state
  const [expandedCommentsSection, setExpandedCommentsSection] = useState({});
  const [showMore, setShowMore] = useState(false);

  const { user } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(profileImage(user?.username));
  }, [dispatch, user?.username]);

  const { profileImageuser } = useSelector((state) => state.profileImage);

  const { getCommunityuser } = useSelector((state) => state.getCommunity);

  const basUrl = `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}`;

  const toggleComments = () => {
    setShowMore((prev) => !prev);
  };

  useEffect(() => {
    dispatch(getCommunity());
  }, [dispatch]);

  useEffect(() => {
    const fetchProfilePictures = async () => {
      if (getCommunityuser) {
        const fetches = getCommunityuser.map(async (user) => {
          try {
            const response = await axios.get(
              `${basUrl}/profile-picture/${user.username}`
            );
            return { username: user.username, image: response.data?.image };
          } catch (error) {
            console.error(
              `Failed to fetch profile picture for ${user.username}:`,
              error
            );
            return { username: user.username, image: null };
          }
        });

        const pictures = await Promise.all(fetches);
        const newProfilePictures = pictures.reduce(
          (acc, { username, image }) => {
            acc[username] = image;
            return acc;
          },
          {}
        );

        setProfilePictures(newProfilePictures);
      }
    };

    fetchProfilePictures();
  }, [getCommunityuser]);

  useEffect(() => {
    const fetchFeedPictures = async () => {
      if (getCommunityuser) {
        const fetches = getCommunityuser.map(async (post) => {
          if (post.photo) {
            try {
              const response = await axios.get(
                `${basUrl}/community_picture/${post.id}`
              );
              return { id: post.id, image: response.data.image };
            } catch (error) {
              console.error(
                `Failed to fetch feed picture for post ${post.id}:`,
                error
              );
              return { id: post.id, image: null };
            }
          }
          return null;
        });

        const pictures = await Promise.all(fetches);
        const validPictures = pictures.filter((picture) => picture !== null);
        const newFeedPictures = validPictures.reduce((acc, { id, image }) => {
          acc[id] = image;
          return acc;
        }, {});

        setFeedPictures(newFeedPictures);
      }
    };

    fetchFeedPictures();
  }, [getCommunityuser]);

  useEffect(() => {
    const fetchLikesData = async () => {
      if (getCommunityuser) {
        const fetches = getCommunityuser.map(async (post) => {
          try {
            const [likeResponse, statusResponse] = await Promise.all([
              axios.get(`${basUrl}/likes/${post.id}`),
              axios.post(`${basUrl}/likes/${post.id}/status`, {
                post_id: post.id,
                username: user?.username,
              }),
            ]);

            return {
              id: post.id,
              likeCount: likeResponse.data.like_count,
              isLiked: statusResponse.data.isLiked,
            };
          } catch (error) {
            console.error(
              `Failed to fetch like data for post ${post.id}:`,
              error
            );
            return { id: post.id, likeCount: 0, isLiked: false };
          }
        });

        const likesData = await Promise.all(fetches);
        const newLikes = {};
        const newLikedPosts = new Set();

        likesData.forEach(({ id, likeCount, isLiked }) => {
          newLikes[id] = likeCount;
          if (isLiked) newLikedPosts.add(id);
        });

        setLikes(newLikes);
        setLikedPosts(newLikedPosts);
      }
    };

    fetchLikesData();
  }, [getCommunityuser, user?.username]);

  useEffect(() => {
    const fetchCommentsData = async () => {
      if (getCommunityuser) {
        const newComments = {};

        for (const post of getCommunityuser) {
          try {
            const commentsResponse = await axios.get(
              `${basUrl}/comments/${post.id}`
            );
            const comments = commentsResponse.data.comments || [];

            const commentsWithImages = [];
            for (const comment of comments) {
              try {
                const imageResponse = await axios.get(
                  `${basUrl}/profile-picture/${comment.username}`
                );
                const image = imageResponse.data?.image || null;
                commentsWithImages.push({
                  ...comment,
                  image,
                });
              } catch (imageError) {
                console.error(
                  `Failed to fetch image for user ${comment.username}:`,
                  imageError
                );
                commentsWithImages.push({ ...comment, image: null });
              }
            }

            newComments[post.id] = commentsWithImages;
          } catch (error) {
            console.error(
              `Failed to fetch comments for post ${post.id}:`,
              error
            );
            newComments[post.id] = [];
          }
        }
        setComments(newComments);
      }
    };

    fetchCommentsData();
  }, [getCommunityuser, comments]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day} ${month} at ${hours}:${minutes}`;
  };

  const toggleLike = async (postId) => {
    try {
      const isCurrentlyLiked = likedPosts.has(postId);
      const url = isCurrentlyLiked ? `${basUrl}/unlikes` : `${basUrl}/likes`;
      const response = await axios.post(url, {
        post_id: postId,
        username: user?.username,
      });

      if (response.status === 200) {
        setLikes((prevLikes) => ({
          ...prevLikes,
          [postId]: isCurrentlyLiked
            ? prevLikes[postId] - 1
            : prevLikes[postId] + 1,
        }));

        setLikedPosts((prevLikedPosts) => {
          const newLikedPosts = new Set(prevLikedPosts);
          isCurrentlyLiked
            ? newLikedPosts.delete(postId)
            : newLikedPosts.add(postId);
          return newLikedPosts;
        });
      }
    } catch (error) {
      console.error(`Failed to toggle like for post ${postId}:`, error);
    }
  };

  const handleCommentChange = (postId, value) => {
    setCommentText((prevComments) => ({
      ...prevComments,
      [postId]: value,
    }));
  };

  const handleAddComment = async (postId) => {
    if (commentText[postId]) {
      try {
        const response = await axios.post(`${basUrl}/comments`, {
          post_id: postId,
          username: user?.username,
          comment: commentText[postId],
        });

        if (response.status === 200) {
          setComments((prevComments) => ({
            ...prevComments,
            [postId]: [
              ...(prevComments[postId] || []),
              {
                username: user?.username,
                comment: commentText[postId],
              },
            ],
          }));
          setCommentText((prevCommentText) => ({
            ...prevCommentText,
            [postId]: "",
          }));
        }
      } catch (error) {
        console.error(`Failed to add comment for post ${postId}:`, error);
      }
    }
  };

  const toggleCommentExpansion = (postId) => {
    setExpandedComments((prevExpanded) => ({
      ...prevExpanded,
      [postId]: !prevExpanded[postId],
    }));
  };

  return (
    <Box
      sx={{
        margin: "0.1rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: 6,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "grey",
          borderRadius: "10px",
        },
        p: 1,
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", sm: "60%" },
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {getCommunityuser?.map((data) => (
          <Box
            sx={{
              border: "1px solid #949494",
              borderRadius: "0.8rem",
              padding: "0.9rem",
              backgroundColor: "#2C2C2C",
            }}
            key={data.id}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  component="img"
                  src={`data:image/jpeg;base64,${
                    profilePictures[data.username] || ""
                  }`}
                  alt="userImg"
                  sx={{
                    width: "6rem",
                    height: "6rem",
                    borderRadius: "50%",
                    objectFit: "cover",
                    display: "block",
                    aspectRatio: "1 / 1",
                  }}
                />
                <Box sx={{ marginLeft: "1rem" }}>
                  <Box
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 600,
                      fontSize: { xs: "1rem", sm: "1.6rem", md: "1.6rem" },
                      lineHeight: { xs: "1.5rem", sm: "2.4rem", md: "2.4rem" },
                      color: "white",
                    }}
                  >
                    {data.username}
                  </Box>
                  <Box
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 400,
                      fontSize: { xs: "1rem", sm: "1.6rem", md: "1.1rem" },
                      lineHeight: { xs: "1.5rem", sm: "2.4rem", md: "1.6rem" },
                      color: "#BFBFBF",
                    }}
                  >
                    {formatDate(data.timestamp)}
                  </Box>
                </Box>
              </Box>
            </Box>
            {data.description && (
              <Box
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 500,
                  fontSize: { xs: "1rem", sm: "1.6rem", md: "1.3rem" },
                  lineHeight: { xs: "1.5rem", sm: "2.4rem", md: "2rem" },
                  color: "#F6F6F6",
                }}
              >
                {data.description}
              </Box>
            )}
            {data.photo && (
              <Box
                component="img"
                src={`data:image/jpeg;base64,${feedPictures[data.id] || ""}`}
                alt="posted img"
                sx={{
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: "0.5rem",
                  marginTop: "1rem",
                }}
              />
            )}
            <Divider sx={{ borderColor: "#7A7A7A", my: "1rem" }} />
            <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
              <Box
                sx={{
                  alignContent: "center",
                  gap: "1rem",
                  display: "flex",
                  cursor: "pointer",
                  color: likedPosts.has(data.id) ? "#686DE0" : "inherit",
                }}
                onClick={() => toggleLike(data.id)}
              >
                <ThumbUpOffAltIcon />
                <Box>Like {likes[data.id] || 0}</Box>
              </Box>
              <Box
                sx={{ alignContent: "center", gap: "1rem", display: "flex" }}
              >
                <ChatIcon />
                <Box>Comment {comments[data.id]?.length || 0}</Box>
              </Box>
            </Box>

            <Divider sx={{ borderColor: "#7A7A7A", my: "1rem" }} />

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
                gap: "0.5rem",
              }}
            >
              <Box
                component="img"
                src={`data:image/jpeg;base64,${profileImageuser?.image}`}
                alt="userImg"
                sx={{
                  width: "5rem",
                  height: "5rem",
                  borderRadius: "50%",
                  objectFit: "cover",
                  display: "block",
                  aspectRatio: "1 / 1",
                }}
              />
              <Box
                sx={{
                  background: "linear-gradient(to right, #7A7A7A, transparent)",
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  borderRadius: "0.5rem",
                }}
              >
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Leave your comment..."
                  value={commentText[data.id] || ""}
                  onChange={(e) => handleCommentChange(data.id, e.target.value)}
                  sx={{
                    flex: 1,
                    input: { color: "white" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />

                <SendIcon
                  onClick={() => handleAddComment(data.id)}
                  sx={{
                    color: "#686DE0",
                    height: "2rem",
                    width: "2rem",
                  }}
                />
              </Box>
            </Box>

            {/* Comments Section */}
            <Box sx={{ marginTop: "1rem" }}>
              {(showMore
                ? comments[data.id]
                : comments[data.id]?.slice(-2) || []
              ).map((comment, commentIdx) => (
                <Box
                  key={commentIdx}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "1rem",
                    padding: "0.5rem 0",
                    borderBottom: "1px solid #7A7A7A",
                  }}
                >
                  <Box
                    component="img"
                    src={`data:image/jpeg;base64,${comment?.image}`}
                    alt="commenter profile"
                    sx={{
                      width: "2.5rem",
                      height: "2.5rem",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                  <Box>
                    <Box
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 600,
                        fontSize: "1rem",
                        color: "#F6F6F6",
                      }}
                    >
                      {comment.username}
                    </Box>
                    <Box
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 400,
                        fontSize: "1rem",
                        color: "#BFBFBF",
                        marginTop: "0.2rem",
                      }}
                    >
                      {comment.comment}
                    </Box>
                  </Box>
                </Box>
              ))}

              {/* Show More/Show Less Button */}
              {comments[data.id]?.length > 2 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "0.5rem",
                    cursor: "pointer",
                    color: "#686DE0",
                    fontWeight: 600,
                  }}
                  onClick={toggleComments}
                >
                  {showMore ? "Show Less" : "Show More"}
                </Box>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default Feed;
