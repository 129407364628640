import React, { useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { FreeMode, Pagination, Navigation } from "swiper/modules";
import { Box, IconButton } from "@mui/material";

function AffiliateCrousel({ items = [] }) {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);

  useEffect(() => {
    const swiperInstance = swiperRef.current?.swiper;
    if (swiperInstance) {
      swiperInstance.params.navigation.prevEl = prevRef.current;
      swiperInstance.params.navigation.nextEl = nextRef.current;

      swiperInstance.navigation.init();
      swiperInstance.navigation.update();
    }
  }, [items]);

  const handleLinkVisit = (data) => {
    const link = data.links;
    if (link) {
      const newWindow = window.open(link, "_blank", "noopener,noreferrer");
      if (!newWindow) {
        alert("Pop-up blocked. Please allow pop-ups for this website.");
      }
    } else {
      alert("Invalid link.");
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: { xs: "90%", sm: "80%" },
        margin: "auto",
      }}
    >
      <IconButton
        ref={prevRef}
        sx={{ ...buttonStyle, left: { xs: "-20px", sm: "-30px", md: "-50px" } }}
      >
        <WestIcon />
      </IconButton>
      <IconButton
        ref={nextRef}
        sx={{
          ...buttonStyle,
          right: { xs: "-20px", sm: "-30px", md: "-50px" },
        }}
      >
        <EastIcon />
      </IconButton>

      <Swiper
        ref={swiperRef}
        freeMode={true}
        slidesPerView={3}
        spaceBetween={30}
        pagination={false}
        navigation={false}
        modules={[FreeMode, Pagination, Navigation]}
        className="mySwiper"
        style={{ height: "100%" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1vw",
            width: "100%",
            height: "auto",
          }}
        >
          {items.map((data, idx) => (
            <SwiperSlide key={idx} style={{ height: "100%" }}>
              {" "}
              <Box
                sx={{
                  position: "relative",
                  borderRadius: "1rem",
                  overflow: "hidden",
                  background: "#1F1F1F",
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                  height: { xs: "18rem", sm: "20rem", md: "26rem" },
                  width: "100%",
                }}
                onClick={() => handleLinkVisit(data)}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%", 
                  }}
                >
                  <Box
                    component="img"
                    src={data?.image}
                    alt={data?.title || "Product Image"}
                    sx={{
                      width: "100%",
                      height: { xs: "10rem", sm: "12rem", md: "15rem" },
                      objectFit: "cover",
                      borderRadius: "0.9rem",
                    }}
                  />
                  <Box
                    sx={{
                      padding: "1rem",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flexGrow: 1,
                    }}
                  >
                    <Box
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 700,
                        fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem" },
                        color: "white",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 3,
                      }}
                    >
                      {data?.title}
                    </Box>
                    <Box
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 700,
                        fontSize: { xs: "1.2rem", sm: "1.4rem", md: "1.6rem" },
                        color: "#686DE0",
                        marginTop: "0.5rem",
                      }}
                    >
                      ₹ {data?.price ? data.price.toLocaleString("en-IN") : "0"}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </SwiperSlide>
          ))}
        </Box>
      </Swiper>
    </Box>
  );
}

// Custom button styles
const buttonStyle = {
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 10,
  backgroundColor: "rgba(0,0,0,0.5)",
  color: "white",
  border: "none",
  borderRadius: "50%",
  width: { xs: "2rem", sm: "2.5rem", md: "3rem" },
  height: { xs: "2rem", sm: "2.5rem", md: "3rem" },
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};

export default AffiliateCrousel;
