import React, { useEffect, useState } from "react";
import WhatHapping from "../component/WhatHapping";
import Home from "../component/Home";
import Games from "../component/Games";
import HallOfFrame from "../component/HallOfFrame";
import Footer from "../component/Footer";
import Layout from "../layout";
import { Helmet } from "react-helmet";
import AffilateProducts from "../component/AffilateProducts";
import axios from "axios";

function HomePage() {
  const baseUrl = `${process.env.REACT_APP_CMS_PROTOCOL}${process.env.REACT_APP_CMS_HOST}${process.env.REACT_APP_CMS_PORT}`;
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/affiliates`);
        setData(response.data.data);
      } catch (err) {
        console.error("Error fetching blog:", err);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Battlesync - Connect, Compete, Conquer</title>
        <meta
          name="description"
          content="BattleSync - Your ultimate hub for esports! Connect with fellow gamers, organize and join free tournaments, hone your skills, and showcase your talent. Engage with passionate gaming communities and take your competitive edge to the next level."
        />
        <meta
          name="keywords"
          content="Battlesync, esports, gaming tournaments, free tournaments, skill improvement, gaming communities, competitive gaming, gaming hub, esports platform, tournament organizers, multiplayer, esports rewards, gamer connection, online competitions, skill showcase, esports events, gaming enthusiasts, tournament participation, professional gaming, gaming practice, esports matches, join tournaments, battle arena, competitive esports, game challenges, esports matchmaking, free entry, gaming platform, skill development, gaming passion, game showcase, esports players"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="Battlesync - Connect, Compete, Conquer"
        />
        <meta
          property="og:description"
          content="Join Battlesync to connect with gamers, participate in tournaments, and earn rewards!"
        />
        <meta property="og:url" content="https://battlesync.in/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://battlesync.in/og-image.jpg"
        />
        <link rel="canonical" href="https://battlesync.in/" />
      </Helmet>

      <Layout>
        <Home />
        <WhatHapping />
        {data?.length > 0 && <AffilateProducts data={data} />}
        <Games />
        <HallOfFrame />
        <Footer />
      </Layout>
    </>
  );
}

export default HomePage;
