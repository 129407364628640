import { Box } from "@mui/material";
import React from "react";

function AboutUsCard({ title, content }) {
  return (
    <Box
      sx={{
        borderRadius: "0.8rem",
        border: "1px solid #949494",
        flex: 1,
        height: "20%",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          background: "#686DE0",
          borderRadius: "0.8rem 0.8rem 0rem 0rem",
          fontFamily: "Poppins, sans-serif !important",
          fontWeight: 500,
          fontSize: { xs: "2.4rem", sm: "3.2rem", md: "3.2rem" },
          lineHeight: { xs: "3.6rem", sm: "4.8rem", md: "4.8rem" },
          color: "white",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          padding: "0rem 1rem",
          zIndex: 1,
        }}
      >
        {title}
      </Box>
      <Box
        sx={{
          fontFamily: "Poppins, sans-serif !important",
          fontWeight: 400,
          fontSize: { xs: "1.4rem", sm: "2rem", md: "2rem" },
          lineHeight: { xs: "2.1rem", sm: "3rem", md: "3rem" },
          color: "white",
          padding: "1rem 1rem",
          textAlign: "justify",
        }}
      >
        {content}
      </Box>
    </Box>
  );
}

export default AboutUsCard;
