import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const basUrl = `${process.env.REACT_APP_CMS_PROTOCOL}${process.env.REACT_APP_CMS_HOST}${process.env.REACT_APP_CMS_PORT}`;

export const blog = createAsyncThunk("blog", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${basUrl}/api/blogs`);
    return response.data;
  } catch (error) {
    return rejectWithValue(
      error.response ? error.response.data : error.message
    );
  }
});

const blogSlice = createSlice({
  name: "blog",
  initialState: {
    isLoading: false,
    user: null,
    status: "idle",
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(blog.pending, (state) => {
        state.isLoading = true;
        state.status = "loading";
      })
      .addCase(blog.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(blog.rejected, (state, action) => {
        state.isLoading = false;
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default blogSlice.reducer;
