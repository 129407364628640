import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import axios from "axios";

function Shop() {
  const basUrl = `${process.env.REACT_APP_CMS_PROTOCOL}${process.env.REACT_APP_CMS_HOST}${process.env.REACT_APP_CMS_PORT}`;
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${basUrl}/api/affiliates`);
        setProducts(response.data.data);
      } catch (err) {
        console.error("Error fetching products:", err);
        setError("Failed to load products");
      }
    };

    fetchProducts();
  });

  const handleLinkVisit = (data) => {
    const link = data.links;
    if (link) {
      const newWindow = window.open(link, "_blank");
      if (
        !newWindow ||
        newWindow.closed ||
        typeof newWindow.closed === "undefined"
      ) {
        alert("Pop-up blocked. Please allow pop-ups for this website.");
      }
    } else {
      alert("Invalid link.");
    }
  };

  if (error) {
    return (
      <Box
        sx={{
          height: "100%",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: 6,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "grey",
            borderRadius: "10px",
          },
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          color="red"
        >
          {error}
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: "100%",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: 6,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "grey",
          borderRadius: "10px",
        },
      }}
    >
      {products.length > 0 ? (
        <Box sx={{ padding: "2rem" }}>
          <Box fontSize="2rem" fontWeight="bold" mb={2} sx={{ color: "white" }}>
            Our Affiliated Products
          </Box>
          <Box
            display="flex"
            flexWrap="wrap"
            gap={2}
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "1vw",
              width: "100%",
              height: "auto",
            }}
          >
            {products.map((product, index) => (
              <Box
                key={index}
                border="1px solid #ccc"
                borderRadius="8px"
                width="calc(33.33% - 16px)"
                backgroundColor="#1F1F1F"
                onClick={() => handleLinkVisit(product)}
                sx={{
                  position: "relative",
                  overflow: "hidden",
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                  height: { xs: "18rem", sm: "22rem", md: "32rem" },
                  marginBottom: "16px",
                }}
              >
                <Box
                  component="img"
                  src={product.image}
                  alt={product.title}
                  sx={{
                    width: "100%",
                    height: "70%",
                    borderRadius: "8px 8px 0 0",
                    objectFit: "contain",
                  }}
                />
                <Box
                  sx={{
                    padding: "1rem",
                    color: "white",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "30%",
                  }}
                >
                  <Box
                    mb={1}
                    sx={{
                      fontFamily: "Poppins, sans-serif !important",
                      fontWeight: 600,
                      fontSize: { xs: "0.6rem", sm: "1rem", md: "1.2rem" },
                      lineHeight: {
                        xs: "0.9rem",
                        sm: "1.5rem",
                        md: "1.8rem",
                      },
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      alignItems: "flex-start",
                    }}
                  >
                    {product.title}
                  </Box>
                  <Box
                    fontWeight="bold"
                    fontSize="1.2rem"
                    mb={1}
                    sx={{
                      fontFamily: "Poppins, sans-serif !important",
                      fontWeight: 700,
                      fontSize: { xs: "0.8rem", sm: "1rem", md: "2rem" },
                      lineHeight: {
                        xs: "1rem",
                        sm: "2rem",
                        md: "3rem",
                      },
                      color: "#686DE0",
                    }}
                  >
                    ₹{" "}
                    {product?.price
                      ? product.price.toLocaleString("en-IN")
                      : "0"}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 700,
              fontSize: { xs: "1.6rem", sm: "2.4rem", md: "4rem" },
              lineHeight: { xs: "2.4rem", sm: "3.6rem", md: "6rem" },
              color: "#505658",
              textAlign: "center",
            }}
          >
            More exciting products
          </Box>
          <Box
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 400,
              fontSize: { xs: "1.6rem", sm: "2.4rem", md: "4rem" },
              lineHeight: { xs: "2.4rem", sm: "3.6rem", md: "6rem" },
              color: "#525252",
              textAlign: "center",
            }}
          >
            COMING SOON!
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Shop;
