import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../services/api";

export const acceptendingRequest = createAsyncThunk(
  "acceptendingRequest",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await api("post", "/accept-pending-request", formData);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const acceptendingRequestSlice = createSlice({
  name: "acceptendingRequest",
  initialState: {
    isLoading: false,
    user: null,
    status: "idle",
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(acceptendingRequest.pending, (state) => {
        state.acceptendingRequestisLoading = true;
        state.acceptendingRequeststatus = "loading";
      })
      .addCase(acceptendingRequest.fulfilled, (state, action) => {
        state.acceptendingRequestisLoading = false;
        state.acceptendingRequeststatus = "succeeded";
        state.acceptendingRequestuser = action.payload.users;
      })
      .addCase(acceptendingRequest.rejected, (state, action) => {
        state.acceptendingRequestisLoading = false;
        state.acceptendingRequeststatus = "failed";
        state.acceptendingRequesterror = action.payload;
      });
  },
});

export default acceptendingRequestSlice.reducer;
