import axios from "axios";
import { isAuthenticated } from "./auth";
import { BASE_URL } from "../constants";

const api = (method, urlEndPoint, data = null, headers = {}, config = {}) =>
  new Promise((resolve, reject) => {
    let defaultHeaders = {
      "Content-Type": "application/json",
    };

    if (isAuthenticated()) {
      defaultHeaders = {
        ...defaultHeaders,
        Authorization: `Bearer ${isAuthenticated()}`,
      };
    }

    axios({
      method,
      url: BASE_URL + urlEndPoint,
      data,
      headers: { ...defaultHeaders, ...headers },
      ...config,
    })
      .then((response) => {
        resolve(response.data); // Resolve with the response data directly
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data); // Reject with the error response data
        } else {
          reject(err.toString());
        }
      });
  });

export default api;
