import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../services/api";

export const allFriend = createAsyncThunk(
  "allFriend",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await api("post", "/all-friends", formData);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const allFriendSlice = createSlice({
  name: "search",
  initialState: {
    isLoading: false,
    user: [],
    status: "idle",
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(allFriend.pending, (state) => {
        state.allFriendisLoading = true;
        state.allFriendstatus = "loading";
      })
      .addCase(allFriend.fulfilled, (state, action) => {
        state.allFriendisLoading = false;
        state.allFriendstatus = "succeeded";
        state.allFrienduser = action.payload.users;
      })
      .addCase(allFriend.rejected, (state, action) => {
        state.allFriendisLoading = false;
        state.allFriendstatus = "failed";
        state.allFrienderror = action.payload;
      });
  },
});

export default allFriendSlice.reducer;
